interface IOption {
      name : string,
      specs : any,
      replace : any,
      styles : any,
}


function addStyles(win:any, styles: any) {
  styles.forEach((style: any) => {
    const link = win.document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", style);
    win.document.getElementsByTagName("head")[0].appendChild(link);
  });
}

function openWindow(url : string, name: string, props: any) {
  let windowRef  = null as any;
  // if (/*@cc_on!@*/ false) {
  //   // for IE only
  //   windowRef = window.open("", name, props);
  //   windowRef.close();
  // }
  windowRef = window.open(url, name, props);
  if (!windowRef?.opener) {
    windowRef.opener = self;
  }
  windowRef.focus();
  return windowRef;
}

const VueHtmlToPaper = {
  install(app :any, options = {}): void {
   const globals = app.prototype || app.config.globalProperties;
   globals.$htmlToPaper = (el: any, localOptions: IOption, cb = () => true) => {
      const defaultName = "_blank",
        defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        defaultReplace = true,
        defaultStyles = [] as any;
      let {
        name = defaultName,
        specs = defaultSpecs,
         replace = defaultReplace,
        styles = defaultStyles,
      } = options as IOption;

      // If has localOptions
      // TODO: improve logic
      if (localOptions != undefined) {

      // console.log(localOptions);

        if (localOptions.name != undefined) name = localOptions.name;
        if (localOptions.specs != undefined) specs = localOptions.specs;
        if (localOptions.replace != undefined) replace = localOptions.replace;
        if (localOptions.styles != undefined) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(",") : "";

      const element = window.document.getElementById(el);

      if (!element) {
        alert(`Element to print #${el} not found!`);
        return;
      }

      const url = "";
      const win = openWindow(url, name, specs);

      win?.document.write(`
        <html>
          <head>
            <title>${window.document.title}</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);

      addStyles(win, styles);

      setTimeout(() => {
        win?.document.close();
        win?.focus();
        win?.print();
        setTimeout(function () {
          window.close();
        }, 1);
        cb();
      }, 1000);

      return true;
    };
  },
};

export default VueHtmlToPaper;


import en from './english';
import tr from './turkish';

const messages : any = {
    en: en,
    tr: tr
};


export default messages;

import IWakalahresult from "../types/wakalahresult"


declare global {
    interface Window {
        dataLayer: any;
    }
}

function useGTMEcommence() {
    window.dataLayer = window.dataLayer || [];

    function eCommenceSuccess(WakalahResult: IWakalahresult) {
        window.dataLayer.push({
            event: 'GAE',
            eC: 'Ecommerce',
            eA: 'Purchase',
            cV: '267.00',
            ecommerce: {
                currencyCode: 'TRY',
                purchase: {
                    actionField: {
                        id: WakalahResult.refNo,
                        affiliation: WakalahResult.countryName, //İletişim bilgilerinde yer alan Ülke
                        revenue: WakalahResult.totalPayment, //Toplam vekalet sipariş tutarı. Küsürat nokta ile ayrılacak. para birimi yer almamalı sadece rakam girilmeli.
                        tax: '0', //Toplam vergi tutarı (KDV). Yoksa 0 gönderilebilir. küsürat nokta ile ayrılacak.
                        shipping: '0' //Varsa toplam kargo tutarı (küsürat nokta ile ayrılacak)
                    },
                    products: getEcProduct(WakalahResult.qurbanDetails)
                }
            }
        });
    }

    function eCommenceFailure(erType: string, erMsg: string) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'GAE',
            eC: 'Error',
            eA: erType, //Hata Kategorisi (Ödeme hatası, form hatası vs)
            cV: erMsg //Hata mesajı (Bakiye yetersiz vs)
        });
    }

    function getEcProduct(qurbanDetails: any) {
        const _products = [];
        for (let i = 0; i < qurbanDetails.length; i++) {
            _products.push({
                id: "sahis" + (i+1), //1. Vekalet sahibi için veritabınımızad bir ID varsa o yoksa sahip1 yazalım
                name: ((qurbanDetails[i].typeNote).split(" ").pop() == "Share" || (qurbanDetails[i].typeNote).split(" ").pop() == "Hisee") ? 'Büyükbaş' : 'Küçükbaş', //Kurban 'Küçükbaş'
                category: (qurbanDetails[i].typeNote).split(" ")[0] , //Vekalet Türü: Vacip, Akika, Adak, Diğer
                variant: qurbanDetails[i].distCityName ? qurbanDetails[i].distCityName : "", //Dağıtımda yer almak istiyorum seçildiğinde çıkan dağıtım yeri, yoksa boş bırakalım.
                brand: qurbanDetails[i].distCountryName ? qurbanDetails[i].distCountryName : "", //Dağıtım Ülkesi
                quantity: qurbanDetails[i].quantity, //Miktar
                price: getWakalahPriceByTypeAndCountry(qurbanDetails[i].distCountryCode, (qurbanDetails[i].typeNote).split(" ").pop()) //Bu sahip için ona düşen kdv dahil fiyat. Küsüratı nokta ile ayırıyoruz. Para birimi yazmıyoruz.  
            });
        }
        return _products;
    }

    function getWakalahPriceByTypeAndCountry(ccode: number, notetype: string) {
        let price = 0;
        let qtype = 1;
        if (notetype.includes("Share") || notetype.includes("Hisse")) {
            qtype = 11;
        } 
        if (ccode == 101 && qtype < 10) {
            price = 3091.00;
        }
        if (ccode == 101 && qtype > 10) {
            price = 2862.00;
        }
        if (ccode != 101 && qtype < 10) {
            price = 2950.00;
        }
        if (ccode != 101 && qtype > 10) {
            price = 2800.00;
        }
        return price;
    }

    return { eCommenceSuccess, eCommenceFailure };
}

export default useGTMEcommence;

import { defineComponent } from "vue";
import BaseWrapper from "@/components/BaseWrapper.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";
import AdditionalServices from "./AdditionalServices.vue";
import userMoneyFormat from "@/composables/userMoneyFormat";

export default defineComponent({
  name: "Query",
  components: {
    BaseWrapper,
    BaseButton,
    BaseTable,
    AdditionalServices
  },
  setup() {
    const { moneyFormat } = userMoneyFormat();

    return {
      moneyFormat,
    };
  },
  data() {
    return {
      refrenceNo: "" as string,
      email: "" as string,
      isQuery: true as boolean,
      isWakalah: false as boolean,
      isDanger: false,
      errorState: this.$t("qureyDescText.value"),
      tableData: {},
      sharePickupLocation: false as boolean,
      needyRecord: null as any,
      country: null,
      city: null,
      sharePickupPaymentStatus: false,
      contact: {
        contactFullname: "",
        quantity: "",
        email: "",
        mobile: "",
        totalPayment: "",
        paymentStatus: "",
        slaugtherStatus: "",
        errorMsg: "",
        installmentCount: 1
      },
      contactTable: [
        { key: this.$t("refrenceNo.value"), value: "Uthman Ahmad 1" },
        { key: this.$t("queryFormFiller.value"), value: "Uthman Ahmad 2" },
      ],
    };
  },
  created() {
    alert("query")
    this.$store.dispatch("getInitSettings", { culture: this.$i18n.locale });
  },
  watch: {
    "$store.state.isClearQueryInputs": function (_nw) {
      if (
        this.isWakalah ||
        this.refrenceNo.length > 0 ||
        this.email.length > 0
      ) {
        this.isWakalah = false;
        this.isQuery = true;
        this.isDanger = false;
        this.refrenceNo = "";
        this.email = "";
        this.errorState = this.$t("qureyDescText.value");
      }
    },
  },
  methods: {
    getStyle1() {
      if (this.isDanger)
        return {
          color: "red",
          fontWeight: "bold",
        };
    },
    getWakalahResult() {
      this.isWakalah = false;
      if (this.refrenceNo.trim() && this.isEmail(this.email.trim())) {
        // console.log(this.refrenceNo, this.email);

        this.$store
          .dispatch("getWakalahResult", {
            email: this.email,
            refNo: this.refrenceNo,
            culture: this.$i18n.locale,
          })
          .then((res: any) => {
            if (this.$store.state.WakalahResult.status) {
              this.isQuery = false;
              this.isWakalah = true;
              //console.log(this.$store.state.WakalahResult);

              const {
                contactFullname,
                quantity,
                email,
                mobile,
                totalPayment,
                paymentStatus,
                slaugtherStatus,
                errorMsg,
                qurbanDetails,
                installmentCount,
                countryName,
                city,
                sharePickupPaymentStatus
              } = this.$store.state.WakalahResult;

              this.contact.contactFullname = contactFullname + (city || countryName ? ` (<span class='capfirst'>${this.$t(city)}</span>/${this.$t(countryName)})`: "");
              this.contact.quantity = quantity;
              this.contact.email = email;
              this.contact.mobile = mobile;
              this.contact.installmentCount = totalPayment / 3 + "/Ay 3 Taksıt";
              this.contact.totalPayment = (isNaN(totalPayment) ? totalPayment : this.moneyFormat(parseFloat(totalPayment))) + " " + this.localCurrency;
              this.contact.paymentStatus = paymentStatus;
              this.contact.slaugtherStatus = slaugtherStatus;

              this.sharePickupLocation = this.$store.state.WakalahResult.sharePickupLocation;
              this.sharePickupPaymentStatus = sharePickupPaymentStatus;
              this.needyRecord = this.$store.state.WakalahResult.needyRecord;
              this.country = this.$store.state.WakalahResult.countryName;
              this.city = this.$store.state.WakalahResult.city;

              this.tableData = qurbanDetails;
            } else {
              this.isDanger = true;
              this.errorState = this.$store.state.WakalahResult.errorMsg;
            }
          });
      }
    },
    goHome() {
      this.$router.push(`/Booking/${this.$i18n.locale}`);
    },
    printMessage(): void {
      const baseUrl = window.location.origin;
      this.$htmlToPaper("printMe", { styles: [`${baseUrl}/customprint.css`] });
    },
    isEmail(email: string) {
      if (email == "") return false;
      var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    },
  },
  computed: {
    localCurrency() {
      if (this.$store.state.InitSettings[0]) {
        return this.$store.state.InitSettings[0].localCurrency;
      }
      return "TRY";
    },
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseTable",
  props: {
    tableData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      btndisable: {
        opacity: ".3",
        cursor: "no-drop",
      },
    };
  },
});

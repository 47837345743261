import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41cefd9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-header" }
const _hoisted_2 = { class: "form-header--wrapper" }
const _hoisted_3 = { class: "count" }
const _hoisted_4 = { class: "countInfo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.count), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.countTilte), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.countMsg), 1)
      ])
    ])
  ]))
}
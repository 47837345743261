<template>
  <div class="wakalah">
    <FormTitle count="2" :countTilte="$t('countTilte1.value')" :countMsg="$t('countMsg1.value')" />

    <div class="content-wrapper">
      <div class="telerror" v-if="ErrMsg">{{ ErrMsg }}</div>
      <div class="telerror" v-if="isPhoneError">
        {{ $t("wakalahtelError.value") }}
      </div>

      <div class="inputs-container">
        <div class="inputs-items">
          <div class="inputs-items-row winput-main">
            <div class="form-element">
              <label>{{ $t("wname.value").toUpperCase() }} </label>
              <input v-model="wName" :placeholder="$t('wname.value')" ref="wnameInput" :title="$t('wname.value')" />
            </div>

            <div class="form-element">
              <label> {{ $t("wMobile.value") }} </label>
              <VueTelInput @input="onInput" :value="wTelValue" v-bind="inputOpts" v-model="wTelValue"></VueTelInput>
            </div>
            <div class="form-element">
              <label>{{ $t("wemail.value").toUpperCase() }} </label>
              <input v-model="wemail" :placeholder="$t('wemail.value')" ref="wemailInput" :title="$t('wemail.value')" />
            </div>
          </div>

          <div class="inputs-items-row">
            <div class="form-element">
              <label> {{ $t("qurban.value") }} </label>
              <select v-model="wQurban" ref="qurbanInput" @change="e => {
                e.target.classList.remove('invalid')
              }">
                <option value="" disabled selected>{{ $t("qurbanTypeSelect.value") }}</option>
                <option value="1" disabled>{{ $t("sheep.value") }}</option>
                <option value="2">{{ $t("cattle.value") }}</option>
              </select>
            </div>
            <div class="form-element">
              <label>{{ $t("wakalah.value") }}</label>

              <select v-model="wakalah" @change="onWakalahSelect" ref="wakalahInput">
                <option value="1">{{ $t("wajib.value") }}</option>
                <option value="2">{{ $t("aqiqah.value") }}</option>
                <option value="3">{{ $t("nadhr.value") }}</option>
                <option value="4">{{ $t("other.value") }}</option>
              </select>
            </div>
            <div class="form-element">
              <label>{{ $t("qurbanqty.value") }}</label>
              <select v-model="qurbanqty">
                <option v-for="(qty, idx) in qurbanqtyOpts" :key="idx" :value="qty">
                  {{ qty }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="wakalah-add" v-if="isShowDistributionFeature">
          <div class="distribute">
            <!-- <div class="distribute-text">
              <span> &#8505; </span>
              <p v-html="$t('distMsg.value')"></p>
            </div> -->
            <div class="distribute-inputs">
              <div class="inputs-items-row" style="justify-content: space-between;">
                <!-- <div class="form-element">
                  <select
                    v-model="distributeCountry"
                    @change="onDistributeCountry"
                  >
                    <option value="" disabled>
                      {{ $t("distributionCountry.value") }}
                    </option>
                    <option :value="215" id="TUR">
                      {{ $t("turkeyTxt.value") }}
                    </option>
                    <option :value="101" id="IDN">
                      {{ $t("indonesiaTxt.value") }}
                    </option>
                  </select>
                </div> -->

                <div class="check-inputgroup" v-if="!isDistributionSelect">
                  <input type="checkbox" id="distribute" value="distribute" ref="distributeCheckref"
                    v-model="distributeCheck" :disabled="isDistributionSelect" @change="onVolunteerSelect" />
                  <label for="distribute">
                    <p>{{ $t("distributechecktext.value") }}</p>
                  </label>
                </div>

                <div class="form-element" v-if="!isDistributionSelect">
                  <select v-model="distributeCity" @change="onDistributeCity" :disabled="isVolunteerSelect">
                    <option value="" disabled>
                      {{ $t("distributionCity.value") }}
                    </option>
                    <option v-for="(state, idx) in stateOptions" :value="state.code" :id="state.code" :key="idx">
                      {{ state.name }}
                    </option>
                  </select>
                  <span class="distribuCity-error" v-if="isDistCityError">{{
                    $t("distribuCityErrorTxt.value")
                  }}</span>
                </div>

                <div class="distribution-namepolicy" v-if="!isNameTransferAbroadSelect">
                  <!-- <div class="check-inputgroup" style="width: initial; margin: initial;">
                    <input type="checkbox" id="NameTransfer" value="eyyup" v-model="NameTransferPolicy" />
                    <label for="NameTransfer" v-html="$t('NameTransferText.value')"></label>
                  </div> -->
                </div>

                <div v-if="isDistributionSelect && isNameTransferAbroadSelect"></div>

                <BaseButton class="btn-lg-d-block" :text="$t('add.value')" @onSubmit="addWakalah"
                  style="margin: initial" />
              </div>

              <div class="distribution-error" v-if="isDistError">
                {{ $t("distErrorMsg.value") }}
              </div>

            </div>
          </div>

          <div class="nametransfer-error" v-if="NameTransferError">
            {{ $t("nameTransferError.value") }}
          </div>

          <BaseButton class="btn-lg-d-none" :text="$t('add.value')" @onSubmit="addWakalah"
            style="background: #2f434f !important; margin-top: 26px" />
        </div>
        <div class="wakalahSecond-add" v-if="!isShowDistributionFeature">
          <div class="wakalah-overseas-box">
            <BaseButton class="" :text="$t('add.value')" @onSubmit="addWakalah" style="
                        background: #2f434f !important;
                        margin-top: 26px;
                      " />
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="card-wrapper" v-if="wakalahList.length > 0">
        <BaseCard v-for="(wakalah, dx) in wakalahList" :key="dx" :cardData="wakalah" />
      </div>
      <div class="qurbanempty-list" v-else>
        {{ $t("qurbanlişstempty.value") }}
      </div>
    </div>
  </div>
</template>

<script lang="tss">

import { defineComponent } from 'vue';
import FormTitle from './FormTitle.vue';
import BaseCard from './BaseCard.vue';
import BaseButton from './BaseButton.vue';
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { uuid } from 'vue-uuid';
import Countries from '@/assets/Countries';
import DistributionCities from '@/assets/DistributionCity';
import userMoneyFormat from "@/composables/userMoneyFormat";

export default defineComponent({
  name: 'FormWakalah',
  components: {
    FormTitle,
    BaseCard,
    BaseButton,
    VueTelInput
  },
  setup() {
    const { moneyFormat } = userMoneyFormat();

    return { moneyFormat }
  },
  data() {
    return {
      wName: "",
      wTelValue: "",
      wemail: "",
      wQurban: "",
      wakalah: "1",
      qurbanqty: "1",
      distributeCountry: "101",
      distributeCity: "",
      distributeCheck: [],
      isDistributionSelect: true,
      isDistributionAbroadSelect: true,
      isNameTransferAbroadSelect: false,
      isVolunteerSelect: true,
      isDistCityError: false,
      inputBlur: {
        opacity: ".3",
        pointerEvents: "none"
      },
      distCountryName: "",
      distCityName: "",
      NameTransferPolicy: [],
      NameTransferError: false,
      stateOptions: [],
      isDistError: false,
      qurbanqtyOpts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
        11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
        41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
      qurbanqtyOpts1: [1],
      qurbanOpts: [],
      inputOpts: {
        mode: "international",
        disabledFetchingCountry: true,
        preferredCountries: ["AU", "TR", "DE", "US"],
        autocomplete: "off",
        maxLen: 25,
        enabledCountryCode: true,
        defaultCountry: 'TR',
        countryCallingCode: '90',
        inputOptions: {
          maxLen: 25,
          placeholder: this.$t('mobilephone.value')
        }

      },
      isPhoneValid: null,
      isPhoneError: false,
      ErrMsg: "",
      ABROAD_COUNTRY_CODE: 101,
      TURKEY_ID: 215
    }
  },
  watch: {

    '$store.state.transferedContactInfo': function (_nw) {
      if (_nw) {
        this.wName = _nw.fullname ? _nw.fullname : '';
        this.wTelValue = _nw.mobile ? _nw.mobile : '+90';
        this.wemail = _nw.email ? _nw.email : '';
      }
    },
    wQurban(_nw) {
      if (_nw == "2") {
        this.distributeCountry = this.TURKEY_ID;
        this.distCountryName = this.$t("turkeyTxt.value");
      }
      else if (_nw == "1") {
        this.distributeCountry = this.ABROAD_COUNTRY_CODE;
        this.distCountryName = this.$t("indonesiaTxt.value");
      }

      this.onDistributeCountry();
    }
  },
  methods: {
    onInput(phone, phoneObject) {
      if (phoneObject) {
        this.isPhoneValid = phoneObject.valid;
        if (phone != undefined && phoneObject.country) {
          let _dailcode = "+" + phoneObject.country.dialCode;
          this.wTelValue = _dailcode;
        }
        if (phoneObject.valid != undefined && phoneObject.valid == true) {
          this.wTelValue = phone
        }
      }
      //console.log(phone, phoneObject, input)
    },
    onWakalahSelect(e) {

      // //disallow distribution participation for non wajib wakalah
      // if (this.wakalah != "1" && (this.distributeCountry.length == 0 || this.distributeCountry != this.TURKEY_ID)) {
      //   this.isDistributionSelect = true; this.isVolunteerSelect = true; this.distributeCheck = []; this.distributeCity = "";
      // } else if (this.wakalah != "1" && (this.distributeCountry.length > 0 || this.distributeCountry == this.TURKEY_ID)) {
      //   this.isDistributionSelect = true; this.isVolunteerSelect = true; this.distributeCheck = []; this.distributeCity = "";
      // } else if (this.wakalah == "1" && (this.distributeCountry.length > 0 || this.distributeCountry != this.TURKEY_ID)) {
      //   this.isDistributionSelect = true; this.isVolunteerSelect = true; this.distributeCheck = []; this.distributeCity = "";
      // } else {
      //   this.isDistributionSelect = false;
      // }

      this.isDistributionSelect = this.distributeCountry != this.TURKEY_ID;
    },
    onDistributeCountry(e) {

      //if (this.wakalah == "1") {//disallow distribution participation for non wajib wakalah
        if (this.distributeCountry == this.TURKEY_ID) {
          this.distributeCheck = [];
          this.NameTransferPolicy = [];
          this.isDistributionSelect = false;
          this.NameTransferError = false;
          this.isDistributionAbroadSelect = true;
        } else {
          this.isDistributionSelect = true;
          this.isVolunteerSelect = true;
          this.isDistributionAbroadSelect = false;
        }
      //}
      // else {
      //   this.isVolunteerSelect = true;
      //   this.isDistributionSelect = true;
      // }

      if (this.distributeCountry == this.TURKEY_ID) { // hide/show fullname transfer permit for abroad vakalah option
        this.isNameTransferAbroadSelect = true;
        this.distributeCheck = [];
        this.NameTransferPolicy = [];
      } else {
        this.isNameTransferAbroadSelect = false;

      }

      if (this.distributeCountry == this.TURKEY_ID) {
        this.stateOptions = DistributionCities;
        this.distributeCity = "";
      } else {
        this.stateOptions = this.stateOptsData("IDN");
      }
      this.isDistError = false;

    },
    onDistributeCity(e) {
      let options = e.target.options;
      if (options.selectedIndex > -1) {
        this.distCityName = options[options.selectedIndex].text;
      }
      if (this.distCityName != "") { this.isDistCityError = false }
    },
    onVolunteerSelect(e) {
      if (e.target.checked) {
        this.isVolunteerSelect = false;
      } else {
        this.isVolunteerSelect = true;
        this.isDistCityError = false;
      }
    },
    stateOptsData(str) {
      const _stateOptions = this.distributionCountry.filter((country) => {
        return country.code3 == str;
      })
      return _stateOptions[0].states;
    },
    getWakalahPriceByTypeAndCountry(ccode, qtype) {
      let price = 0;
      if (this.isShowDistributionFeature) {
        if (ccode == 101 && qtype < 10) {
          price = this.$store.state.InitSettings[0].smallHead;
        }
        if (ccode == 101 && qtype > 10) {
          price = this.$store.state.InitSettings[0].bigHead;
        }
        if (ccode != 101 && qtype < 10) {
          price = this.$store.state.InitSettings[0].smallHead;
        }
        if (ccode != 101 && qtype > 10) {
          price = this.$store.state.InitSettings[0].bigHead;
        }
      } else {
        if (ccode == this.appClient && qtype < 10) {
          price = this.$store.state.InitSettings[0].smallHead;
        }
        if (ccode == this.appClient && qtype > 10) {
          price = this.$store.state.InitSettings[0].bigHead;
        }
      }

      return price;
    },
    addWakalah() {

      const qNote = this.$refs.qurbanInput.options[this.$refs.qurbanInput.selectedIndex].text;
      const wNote = this.$refs.wakalahInput.options[this.$refs.wakalahInput.selectedIndex].text;
      this.$refs.wnameInput.classList.remove("invalid");
      this.$refs.wemailInput.classList.remove("invalid");
      this.ErrMsg = "";
      this.isPhoneError = false;
      this.isDistError = false;
      this.NameTransferError = false;
      this.$refs.qurbanInput.classList.remove('invalid');

      if (this.wName == "") {
        this.$refs.wnameInput.placeholder = this.$t('fieldcannotbeEmpty.value');
        this.$refs.wnameInput.classList.add("invalid");
      }
      if (this.wemail == "") {
        this.$refs.wemailInput.placeholder = this.$t('fieldcannotbeEmpty.value');
        this.$refs.wemailInput.classList.add("invalid");
      }
      if (!this.isEmail(this.wemail) && this.wemail != "") {
        this.$refs.wemailInput.placeholder = this.$t('wemailerror.value');
        this.$refs.wemailInput.classList.add("invalid");
        this.wemail = "";
      }

      if (this.wName && this.wemail && !this.isPhoneValid) { this.isPhoneError = true; }


      //Set control for Distribution Feature
      if (this.isShowDistributionFeature) {
        if (this.distributeCountry == "") {
          this.isDistError = true;
          return;
        }
        if (this.isVolunteerSelect == false && this.distributeCity == "") {
          this.isDistCityError = true;
          return;
        }

        if (this.wQurban == "") {
          this.$refs.qurbanInput.classList.add('invalid');
          return;
        }
        // else if((this.distributeCountry.length != 0 && this.distributeCountry  != this.TURKEY_ID) && this.NameTransferPolicy.length == 0){
        //   this.NameTransferError = true;
        //   return;
        // }
      }


      //quata control
      if (this.wTelValue != '' && this.isPhoneValid && this.isEmail(this.wemail) && this.wName) {
        this.$store.dispatch('getQuataInfo', { type: this.wQurban, distConutry: this.distributeCountry, amount: this.qurbanqty, special: false, culture: this.$i18n.locale })
          .then(() => {

            if (this.$store.state.QuotaInfo.status == true) {

              let wakalahPayload = {};
              let getWakalahActualPrice = 0;

              if (this.isShowDistributionFeature) {
                getWakalahActualPrice = this.getWakalahPriceByTypeAndCountry(this.distributeCountry, this.getWakalahType);
                wakalahPayload = {
                  id: uuid.v4(),
                  fullname: this.wName,
                  quantity: parseInt(this.qurbanqty),
                  typeNote: qNote + " " + wNote,
                  displayPrice: this.getDisplayPrice(this.wQurban, this.distributeCountry),
                  price: getWakalahActualPrice,
                  total: getWakalahActualPrice * parseInt(this.qurbanqty),
                  qurbanType: (this.wQurban == '2' ? (parseInt(this.wakalah) + 10) : parseInt(this.wakalah)),
                  mobile: this.wTelValue,
                  email: this.wemail,
                  distCountryCode: this.distributeCountry,
                  distCountryName: this.distCountryName,
                  distCityName: this.distCityName,
                  willDistribute: this.distributeCheck.length > 0 ? true : false

                };
              } else {
                getWakalahActualPrice = this.getWakalahPriceByTypeAndCountry(101, this.getWakalahType);
                wakalahPayload = {
                  id: uuid.v4(),
                  fullname: this.wName,
                  quantity: parseInt(this.qurbanqty),
                  typeNote: qNote + " " + wNote,
                  displayPrice: this.getDisplayPrice(this.wQurban, 101),
                  price: getWakalahActualPrice,
                  total: getWakalahActualPrice * parseInt(this.qurbanqty),
                  qurbanType: (this.wQurban == '2' ? (parseInt(this.wakalah) + 10) : parseInt(this.wakalah)),
                  mobile: this.wTelValue,
                  email: this.wemail,
                  distCountryName: "INDONESIA",
                  distCityName: "",
                  distCountryCode: "101",
                }
              }

              this.$store.dispatch('addWakalah', wakalahPayload);

              this.wName = "";
              this.wemail = "";
              this.$refs.wnameInput.placeholder = this.$t('wname.value');
              this.$refs.wemailInput.placeholder = this.$t('wemail.value');
              this.NameTransferPolicy = [];

            } else {
              this.ErrMsg = this.$store.state.QuotaInfo.message;
            }
          });
      }
    },
    isEmail(email) {
      if (email == '') return false;
      var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    },
    getDisplayPrice(wType, distType) {
      let price = "";
      if (this.isShowDistributionFeature) {
        if (wType == "1" && distType == "101") {
          price = this.moneyFormat(this.$store.state.InitSettings[0].smallHead * parseInt(this.qurbanqty)) + " TL";
        }
        if (wType == "2" && distType == "101") {
          price = this.moneyFormat(this.$store.state.InitSettings[0].bigHead * parseInt(this.qurbanqty)) + " TL";
        }
        if (wType == "1" && distType == "215") {
          price = this.moneyFormat(this.$store.state.InitSettings[0].smallHead * parseInt(this.qurbanqty)) + " " + this.localCurrency;
        }
        if (wType == "2" && distType == "215") {
          price = this.moneyFormat(this.$store.state.InitSettings[0].bigHead * parseInt(this.qurbanqty)) + " " + this.localCurrency;
        }
      }
      if (!this.isShowDistributionFeature) {
        if (wType == "1") {
          price = this.moneyFormat(this.$store.state.InitSettings[0].smallHead * parseInt(this.qurbanqty)) + " " + this.localCurrency;
        }
        if (wType == "2") {
          price = this.moneyFormat(this.$store.state.InitSettings[0].bigHead * parseInt(this.qurbanqty)) + " " + this.localCurrency;
        }
      }

      return price;
    }

  },
  computed: {
    wakalahList() {
      return this.$store.state.WakalahList;
    },
    getWakalahPrice() {
      return this.wQurban == '2' ? this.$store.state.InitSettings[0].bigHead : this.$store.state.InitSettings[0].smallHead;
    },
    priceDeffirenceForAbroad() {
      return this.$store.state.InitSettings[0].abroadPriceDif;
    },
    distributionCountry() {
      return Countries.filter((country) => {
        if (country.code3 == "IDN" || country.code3 == "TUR") {
          return country.states;
        }
      })
    },
    getWakalahType() {
      return this.wQurban == '2' ? (parseInt(this.wakalah) + 10) : parseInt(this.wakalah);
    },
    isShowDistributionFeature() {
      if (this.$store.state.InitSettings[0]) {
        return (this.appClient == this.$store.state.INDONESIA_CLIENT_APP_ID) ? false : true;
      }
      return false
    },
    localCurrency() {
      return this.$store.state.InitSettings[0].localCurrency;
    },
    appClient() {
      return this.$store.state.InitSettings[0].clientID;
    }
  }
});
</script>

<style scoped lang="scss">
.wakalah {
  width: 100%;
  margin-bottom: 1rem;
}

.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}

.inputs-container {
  display: flex;
  margin: 12px 0px;
  padding: 10px;
  flex-direction: column;
}

.inputs-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputs-items-row {
  display: flex;
  width: 100%;
  align-items: center;
}

.form-element {
  width: 300px;
  margin: 0px 12px;

  label {
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: var(--maingray);
    margin-left: 4px;
  }

  input,
  select {
    height: 46px;
    -webkit-appearance: none;
    border: 1px solid #e1e1e1;
    padding: 8px 6px;
    outline: none;
    font: 13px "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--maingray);
    margin: 0;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 8px;
    border-color: #80919a;
  }

  select {
    cursor: pointer;
    -webkit-appearance: auto !important;
  }
}

.winput-main {
  .form-element {
    input.invalid::placeholder {
      color: red;
    }
  }
}

select.invalid {
  color: red;
}

.wakalah-add {
  width: 100%;
  align-self: flex-end;
  margin-bottom: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.inputs-items {
  display: flex;
}

.form-element {
  .vue-tel-input {
    height: 46px;
    border-radius: 8px;
    border-color: #80919a;
    margin-bottom: 18px;

    .vti__input {
      width: 75% !important;
    }

    input {
      height: inherit;
      -webkit-appearance: none;
      border: 1px solid #e1e1e1;
      padding: 8px 6px;
      outline: none;
      font: 13px "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: var(--maingray);
      margin: 0;
      width: 75%;
      display: block;
      margin-bottom: 20px;
      background: #fff;
    }
  }
}

.qurbanempty-list {
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  padding-bottom: 32px;
}

.telerror {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #00b3bd;
  background-color: #f2dede;
  font-size: 12px;
  font-weight: bold;
  width: 80%;
  margin: 20px auto;
}

.distribute-inputs {
  .inputs-items-row {
    button {
      width: 220px;
      background: var(--verydarkblue);
    }

    .check-inputgroup {
      width: 285px;
      margin-bottom: 20px;

      label {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

.distribute {
  display: flex;
  flex-direction: column;
}

.distribute-text {
  display: flex;
  margin-bottom: 20px;
  padding-left: 12px;

  span {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    background: #4d4d4d;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    width: calc(100% - 216px);
    margin: 0;
  }
}

.distribution-error,
.nametransfer-error {
  font-size: 12px;
  color: red;
  padding-left: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.distribuCity-error {
  margin-top: -20px;
  margin-left: 4px;
  display: block;
  font-size: 12px;
  color: red;
  font-weight: bold;
}

.wakalah-add {
  .check-inputgroup {
    display: flex;
    align-items: center;

    label {
      margin: 4px 6px;
      color: #4d4d4d;
      font-size: 12px;
      font-weight: 600;
      font-family: "Roboto";
      font-style: normal;
      text-align: left;
    }
  }
}

.wakalahSecond-add {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 20px;
}

.wakalah-overseas-box {
  width: 200px;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .btn-lg-d-block {
    display: inline !important;
  }

  .btn-lg-d-none {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {

  .inputs-container,
  .inputs-items-row {
    flex-direction: column;
    justify-content: center;
  }

  .form-element {
    margin: 0;
  }

  .wakalah-add {
    text-align: center;
  }

  .inputs-items,
  .form-element,
  .wakalah-add,
  .wakalah-add button {
    width: 100% !important;
    max-width: 100%;
  }

  .distribute-text {
    display: flex;
    align-items: center;
    order: 1;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      background: #4d4d4d;
      border-radius: 20px;
      color: #ffffff;
      font-size: 14px;
    }

    p {
      width: calc(100% - 24px);
    }
  }

  .distribution-error {
    order: 2;
  }

  .distribute-inputs {
    order: 3;
  }

  .btn-lg-d-block {
    display: none !important;
  }

  .btn-lg-d-none {
    display: block !important;
  }
}
</style>

/* eslint-disable */

import axios from 'axios';
axios.defaults.headers.common = {
    "Content-Type": "application/json"
}




function clientBrowser() {
    var ua = navigator.userAgent;
    var tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}



// axios.defaults.baseURL = "api";
export function get(url: string): any {
    return new Promise(async (resolve) => {
        return axios.get(url)
            .then((request) => {
                resolve({
                    data: request.data,
                    status: request.status
                });
            }).catch(err => {
                resolve({
                    success: false,
                    status: 400
                });
            });
    });
}

export function post(url: string, params: any): any {
    return new Promise(async (resolve, reject) => {
        try {
            //if (window.location.toString().match(/localhost|test1444|192\.168/)) {
                url = "https://test1444.mecvakfi.org" + url;
            //}
            
            const { data, status, statusText } = await axios.post(url, params);
            return response({
                data,
                status,
                message: statusText,
                resolve,
                reject
            });
        } catch (err: any) {
            if (err.response && err.response.data && err.response.status)
                var { data, status } = err.response;
            else
                var data = err.message || err;

            return response({
                data,
                status,
                message: data.message,
                resolve,
                reject
            });
        }
    });
}


function response({ data, status, message, resolve }: any) {
    return resolve({ data, status, message });
}
import IContact from "@/types/Contact";
import IFields from "@/types/FieldError";
import IPaymentResponse from "@/types/PaymentResponse";
import IQuota from "@/types/Quota";
import IQuotaError from "@/types/QuotaError";
import ISettings from "@/types/Settings";
import ITotalHissePayment from "@/types/TotalWakalah";
import IWakalah from "@/types/wakalah";
import IWakalahResult from "@/types/WakalahResult";
import I3DPaymentError from "@/types/I3DPaymentError";
import IErrorSettings from "@/types/ErrorSetting";
import { createStore } from "vuex";
import { post } from "../api/Request";
import calculteTotalHisse from "../Mixins/hisseCalculate";
import IInstallment from "@/types/IInstallment";
import installmentCalculateList from "@/Mixins/installmentCalculateList";

export default createStore({
  state: {
    InitSettings: [] as Array<ISettings>,
    WeatherForcast: [],
    WakalahList: [] as Array<IWakalah>,
    QuotaInfo: {} as IQuota,
    SelectedCurrency: "TRY",
    TotalHissePayment: { qty: 0, total: 0 } as ITotalHissePayment,
    ContactInfo: { fullname: "", mobile: "", email: "" } as IContact,
    InputsDataError: { valid: false, type: 0, msg: "" } as IFields,
    paymentStatus: {} as IPaymentResponse,
    WakalahResult: {} as IWakalahResult,
    errorSetting: {} as IErrorSettings,
    QuotaInfoError: {} as IQuotaError,
    ThreeDPaymentError: {} as I3DPaymentError,
    paymentError: "",
    isClearQueryInputs: false,
    SelectedInstallment: 0,
    InstallmentPercentage: 0.2,
    installData: { percentage: 0.2, type: 0 } as IInstallment,
    INDONESIA_CLIENT_APP_ID: 101,
    transferedContactInfo: { fullname: "", mobile: "", email: "" } as IContact,
  },
  mutations: {
    INITSETTINGS(state, data) {
      state.InitSettings = data;
    },
    ERRORINITSETTINGS(state, data) {
      console.log(data);
      state.errorSetting = data;
    },
    QUOTAINFO(state, data) {
      state.QuotaInfo = data;
    },
    QUOTAINFOERROR(state, data) {
      state.QuotaInfoError = data;
    },
    WEATHERFORECAST(state, data) {
      state.WeatherForcast = data;
    },
    WAKALAHLIST(state, data) {
      state.WakalahList.push(data);
      if (state.WakalahList.length > 0) {
        if (state.SelectedInstallment == 3) {
          state.installData.percentage = state.InstallmentPercentage;
          state.installData.type = state.SelectedInstallment;
          state.WakalahList = installmentCalculateList(
            state.WakalahList,
            state.installData,
            state.InitSettings[0]
          );
        }
        const { qty, total, installPrice } = calculteTotalHisse(
          state.WakalahList,
          state.SelectedCurrency,
          state.InitSettings[0].exchangeRate,
          state.installData
        );
        state.TotalHissePayment.qty = qty;
        state.TotalHissePayment.total = total;
        state.TotalHissePayment.installPrice = installPrice;
      }
    },
    SELECTEDCURRENCY(state, curr) {
      state.SelectedCurrency = curr;
      //  if(state.WakalahList.length > 0){
      //   const { qty, total, installPrice} = calculteTotalHisse(state.WakalahList, curr, state.InitSettings[0].exchangeRate, state.installData)
      //   state.TotalHissePayment.qty = qty;
      //   state.TotalHissePayment.total= total;
      //   state.TotalHissePayment.installPrice = installPrice;
      //  }
    },
    REMOVEWAKALAH(state, id) {
      state.WakalahList = state.WakalahList.filter((w) => w.id != id);
      const { qty, total, installPrice } = calculteTotalHisse(
        state.WakalahList,
        state.SelectedCurrency,
        state.InitSettings[0].exchangeRate,
        state.installData
      );
      state.TotalHissePayment.qty = qty;
      state.TotalHissePayment.total = total;
      state.TotalHissePayment.installPrice = installPrice;
    },
    UPDATECONTACTINFO(state, data) {
      state.ContactInfo.fullname = data[0];
      state.ContactInfo.mobile = data[1];
      state.ContactInfo.email = data[2];
      state.ContactInfo.country = data[3];
      state.ContactInfo.city = data[4];
      state.ContactInfo.town = data[5];
      state.ContactInfo.countryName = data[6];
      state.ContactInfo.countryCode = data[7];
      state.ContactInfo.countryPhoneCode = data[8];
    },
    INPUTSDATAERROR(state, err) {
      state.InputsDataError = err;
    },
    PAYMENTSTATUS(state, res) {
      state.paymentStatus = res;
    },
    PAYMENTERROR(state, msg) {
      state.paymentError = msg;
    },
    GETWAKALAHRESULT(state, data) {
      state.WakalahResult = data;
    },
    PAYMENT_3D_ERROR_MSG(state, data) {
      state.ThreeDPaymentError = data;
    },
    SELECTEDINSTALLMENT(state, val) {
      state.SelectedInstallment = val;
      state.installData.percentage = state.InstallmentPercentage;
      state.installData.type = state.SelectedInstallment;
      state.WakalahList = installmentCalculateList(
        state.WakalahList,
        state.installData,
        state.InitSettings[0]
      );

      if (state.WakalahList.length > 0) {
        const { qty, total, installPrice } = calculteTotalHisse(
          state.WakalahList,
          state.SelectedCurrency,
          state.InitSettings[0].exchangeRate,
          state.installData
        );
        state.TotalHissePayment.qty = qty;
        state.TotalHissePayment.total = total;
        state.TotalHissePayment.installPrice = installPrice;
      }
    },
    CLEARQUERYINPUTS(state, val) {
      state.isClearQueryInputs = val;
    },
    TRANSFERCONTACTDATA(state, data) {
      state.transferedContactInfo = data;
    },
  },
  actions: {
    async getInitSettings({ commit }, params) {
      const { data, status, message } = await post("/api/getSettings", params);
      if (status == 200) {
        commit("INITSETTINGS", data.data);
      } else {
        commit("ERRORINITSETTINGS", {
          data: data.data,
          status: status,
          message: message,
        });
      }
    },
    async getQuataInfo({ commit }, params) {
      const { data, status, message } = await post("/api/getQuotaInfo", params);
      if (status === 200) {
        commit("QUOTAINFO", data);
      }

      //else if (status == 404) {
      //   commit('QUOTAINFOERROR', { 'status': status, statusText: message, 'data': data, });
      //}
    },
    async makePayment({ commit }, order) {
      const { data, status } = await post("/api/makeorder", order);
      if (status === 200) {
        commit("PAYMENTSTATUS", data);
      } else if (status == 404) {
        commit("PAYMENTERROR", data.data.message);
      }
    },
    async makesharepickuporder({ commit }, order) {
      const { data, status } = await post("/api/makesharepickuporder", order);
      if (status === 200) {
        commit("PAYMENTSTATUS", data);
      } else {
        commit("PAYMENTERROR", data.message || data);
      }
    },
    async getWakalahById({ commit }, param) {
      const { data, status } = await post("/api/getWakalahById", param);
      if (status === 200) {
        commit("GETWAKALAHRESULT", data);
      }
    },
    async getWakalahByRefNo({ commit }, param) {
      const { data, status } = await post("/api/getWakalahByRefNo", param);
      if (status === 200) {
        commit("GETWAKALAHRESULT", data);
      }
    },

    async get3DPaymentErrorById({ commit }, param) {
      const { data, status } = await post("/api/get3DPaymentErrorById", param);
      if (status === 200) {
        commit("PAYMENT_3D_ERROR_MSG", data);
      }
    },

    async getWakalahResult({ commit }, param) {
      const { data, status } = await post("/api/getQurbanStatus", param);
      if (status === 200) {
        commit("GETWAKALAHRESULT", data);
      }
    },
    addWakalah({ commit }, data) {
      commit("WAKALAHLIST", data);
    },
    selectedCurrency({ commit }, curr) {
      commit("SELECTEDCURRENCY", curr);
    },
    getInstallmentPrice({ commit }, installSelect) {
      commit("SELECTEDINSTALLMENT", installSelect);
    },
    removeWakalah({ commit }, id) {
      commit("REMOVEWAKALAH", id);
    },
    updateContactInfo({ commit }, data) {
      commit("UPDATECONTACTINFO", data);
    },
    inputsDataError({ commit }, err) {
      commit("INPUTSDATAERROR", err);
    },
    isClearQueryInputs({ commit }, param) {
      commit("CLEARQUERYINPUTS", param);
    },
    transferContactInfo({ commit }, param) {
      commit("TRANSFERCONTACTDATA", param);
    },
  },
  modules: {},
});


import { ref } from "vue";


function useCreditCardType(){
   const result = ref(""); 
    function cardType(cardnum: string): string {
       const firstChar = parseInt(cardnum[0]);
       const _cardNum = parseInt(cardnum);
       if(firstChar === 4){

            if((_cardNum >= 40259000 && _cardNum <= 40259099) || 
                _cardNum === 40336025 || 
                _cardNum === 40336026 || 
                _cardNum === 43984700 || 
                _cardNum === 48367320 ||
                _cardNum === 48367330 ||   
            (_cardNum >= 48367332 && _cardNum <= 48367350)
            ){ result.value = 'VISA'; 
            }else{ result.value = 'NOT SUPPORT'; }

        }
         
        if(firstChar === 5){
            if(_cardNum == 51259510 || 
             (_cardNum >= 51889600 && _cardNum <= 51889605) ||
             _cardNum == 51889630 ||
             (_cardNum >= 52018000 && _cardNum <= 52018005) ||
             (_cardNum >= 52531200 && _cardNum <= 52531299) ||
             _cardNum == 52708300 ||
             _cardNum == 52732700 ||
             (_cardNum >= 51166000 && _cardNum <= 51166019) ||
             _cardNum == 53980300 
            ){ result.value = 'MASTERCARD';
            } else{ result.value = 'NOT SUPPORT'; }
        }

        return result.value;
    }
    return { cardType }
}






//SUPPORTED CARDS - VISA
// 40259000-40259099
// 40336025-40336025
// 40336026-40336026
// 43984700-43984700
// 48367320-48367320
// 48367330-48367330
// 48367332-48367350

//SUPPORTED CARDS - MASTERCARD
// 51259510-51259510
// 51889600-51889605
// 51889630-51889630
// 52018000-52018000
// 52018000-52018005
// 52531200-52531299
// 52708300-52708300
// 52732700-52732700
// 51166000-51166000
// 51166000-51166019
// 53980300-53980300




export default useCreditCardType;
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb36be34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("tableheadOwner.value")), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("tableheadPhone.value")), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("tableheadEmail.value")), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("tableheadQty.value")), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("tableheadTypeNote.value")), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("distributionCountry.value")), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (wakalah) => {
          return (_openBlock(), _createElementBlock("tr", {
            class: "liste",
            key: wakalah.id
          }, [
            _createElementVNode("td", null, _toDisplayString(wakalah.fullname), 1),
            _createElementVNode("td", null, _toDisplayString(wakalah.mobile), 1),
            _createElementVNode("td", null, _toDisplayString(wakalah.email), 1),
            _createElementVNode("td", null, _toDisplayString(wakalah.quantity), 1),
            _createElementVNode("td", null, _toDisplayString(wakalah.typeNote), 1),
            _createElementVNode("td", null, _toDisplayString(wakalah.distCountryName), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}
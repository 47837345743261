
import { defineComponent } from "vue";
import BaseButton from "./components/BaseButton.vue";

export default defineComponent({
  components: {
    BaseButton,
  },
  data() {
    return {
      isScrollDown: false as boolean,
      isFixedFooter: false,
      isQueryBtnClicked: false,
    };
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      currentScrollPosition == 0
        ? (this.isScrollDown = false)
        : (this.isScrollDown = true);
    },
    gotop() {
      window.scrollTo(0, 0);
    },
    changeLang(lang: string) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      this.$router.push({
        name: this.$route.name || "Booking",
        params: { lang: this.$i18n.locale },
      });
    },
    toWakalStatus() {
      console.log("asda")
      if (location.href.includes("/query/")) {
        this.isQueryBtnClicked = !this.isQueryBtnClicked;
        this.$store.dispatch("isClearQueryInputs", this.isQueryBtnClicked);
      } else {
        this.$router.push(`/query/${this.$i18n.locale}`);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
});

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Booking from '../views/Booking.vue'
import Query from '../views/Query.vue'
import Notification from '../views/Notification.vue'
import Success from '../views/Success.vue'
import Paynemtfailure from '../views/Paynemtfailure.vue'
import NotFound from '../views/NotFound.vue'
import getCulture from '../main'


const routes: Array<RouteRecordRaw> = [
       {
          path: '/',
          alias: '/booking',
          name: 'Booking',
          component: Booking,
          meta: { title: 'Vekâlet' }
       },
       {
         path: '/booking/:lang',
         name: 'Booking',
         component: Booking,
         meta: { title: 'Vekâlet' }
      },
      {
          path: '/query/:lang',
          name: 'Query',
          component: Query, // component: () => import(/* webpackChunkName: "about" */ '../views/Query.vue'),
          meta: { title: 'Vekâlet Sorgu' }
      },
      {
          path: '/notification/:lang',
          name: 'Notification',
          component: Notification,
          meta: { title: 'Bildirim' }
      },
       {
          path: '/paymentfailure/:id/:lang',
          name: 'failure',
          component: Paynemtfailure,
          meta: { title: 'Ödeme Başarşız' }
      },
      {
          path: '/paymentsuccess/:id/:lang',
          name: 'Success',
          component: Success,
          meta: { title: 'Ödeme Başarılı' }
      },
    {
        path: '/notfound/:lang',
        name: 'NotFound',
        component: NotFound,
        meta: { title: 'Sayfa Bulunamadı' }
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? `${to.meta.title} | 18. MEC Uluslararası Kurban Organizasyonu` : ' 18. MEC Uluslararası Kurban Organizasyonu';
    const _routes = [...router.options.routes];
    const _culture = ['tr', 'en'];
    const _trEn = to.path.slice(-2);
    const _lang = _culture.includes(_trEn);
    const _path = (to.path).substr(1);

    if (isRouteValid(_path, _routes) && !_lang) {
        window.location.href = to.path + "/" + getCulture();
    }

    if (to.matched.length == 0 && !isRouteValid(_path, _routes)) {
        window.location.href = "/notfound/" + getCulture();
    }

    if (to.path == '/') { window.location.href = "/booking/" + getCulture(); }

    next();
});


const isRouteValid = (val: any, allroutes: any) => {
    if (val == 'undefined') { return false; } else {
     return (allroutes.filter((e: any) => e.name.toLowerCase() == val.toLowerCase())).length > 0 ? true : false; } 
}



export default router

const DistributionCity = [
  {
    code: "01",
    name: "Adana",
    subdivision: null,
  },
  {
    code: "02",
    name: "Adıyaman",
    subdivision: null,
  },
  {
    code: "06",
    name: "Ankara",
    subdivision: null,
  },
  {
    code: "16",
    name: "Bursa",
    subdivision: null,
  },
  {
    code: "27",
    name: "Gaziantep",
    subdivision: null,
  },
  {
    code: "31",
    name: "Hatay",
    subdivision: null,
  },
  {
    code: "34",
    name: "İstanbul",
    subdivision: null,
  },
  {
    code: "46",
    name: "Kahramanmaraş",
    subdivision: null,
  },
  {
    code: "38",
    name: "Kayseri",
    subdivision: null,
  },
  {
    code: "41",
    name: "Kocaeli",
    subdivision: null,
  },
  {
    code: "42",
    name: "Konya",
    subdivision: null,
  },
  {
    code: "44",
    name: "Malatya",
    subdivision: null,
  },
  {
    code: "80",
    name: "Osmaniye",
    subdivision: null,
  },
  {
    code: "54",
    name: "Sakarya",
    subdivision: null,
  },
  {
    code: "63",
    name: "Şanlıurfa",
    subdivision: null,
  },
];
export default DistributionCity;


import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseModal",
  props: {
    Size: String,
    isShow: Boolean,
  },
  data() {
    return {
      medium: {
        maxWidth: "528px!important",
      },
      large: {
        maxWidth: "770px!important",
      },
      showpopup: {
        opacity: "1!important",
        pointerEvents: "initial!important",
      },
    };
  },
});

// import ITotalHissePayment from "@/types/TotalWakalah";
import IWakalah from "@/types/wakalah";
import ISettings from '@/types/Settings'; 
// import IInstallment from "@/types/IInstallment";
// import IExRate from "@/types/IExRate";




interface IInstallment{
   percentage: number,
   type: number
}

function installmentCalculateList(arr:Array<IWakalah>, installmentType: IInstallment, priceSettings: ISettings): any{
        const _arr = arr;
         for(let i=0; i< _arr.length; i++){
           if(_arr[i].qurbanType < 10){
                      if (_arr[i].distCountryCode == 101 && installmentType.type == 3)
                        {
                          _arr[i].price = priceSettings.installmentPrices.smallHead;
                          _arr[i].displayPrice = priceSettings.installmentPrices.smallHead + " TL";
                        }
                        else if (_arr[i].distCountryCode == 101 && installmentType.type == 0)
                        {
                            _arr[i].price = priceSettings.smallHead;
                            _arr[i].displayPrice = priceSettings.smallHead + " TL";
                        }
                        else  if (_arr[i].distCountryCode != 101 && installmentType.type == 3)
                        {
                            _arr[i].price = priceSettings.installmentPrices.smallHead;
                            _arr[i].displayPrice = _arr[i].price+ " "+ priceSettings.localCurrency;
                        }
                        else if (_arr[i].distCountryCode != 101 && installmentType.type == 0)
                        {
                            _arr[i].price = priceSettings.smallHead;
                            _arr[i].displayPrice = _arr[i].price+ " "+ priceSettings.localCurrency;
                        }
           }else if(_arr[i].qurbanType > 10){

                      if (_arr[i].distCountryCode == 101 && installmentType.type == 3)
                        {
                          _arr[i].price = priceSettings.installmentPrices.bigHead;
                          _arr[i].displayPrice = priceSettings.installmentPrices.bigHead+ " TL";
                        }
                        else if (_arr[i].distCountryCode == 101 && installmentType.type == 0)
                        {
                          _arr[i].price = priceSettings.bigHead;
                          _arr[i].displayPrice =  priceSettings.bigHead + " TL";
                        }
                        else  if (_arr[i].distCountryCode != 101 && installmentType.type == 3)
                        {
                           _arr[i].price = priceSettings.installmentPrices.bigHead;
                           _arr[i].displayPrice = _arr[i].price+ " "+ priceSettings.localCurrency;
                        }
                        else if (_arr[i].distCountryCode != 101 && installmentType.type == 0)
                        {
                          _arr[i].price = priceSettings.bigHead;
                          _arr[i].displayPrice = _arr[i].price+ " "+ priceSettings.localCurrency;
                        }
           }

          _arr[i].total = _arr[i].price * _arr[i].quantity;
        }
   return _arr;   
}



 export default installmentCalculateList;       
import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import messages from "@/Languages/messages";
import VueTelInput from 'vue3-tel-input';
import VueHtmlToPaper from "@/plugins/VueHtmlToPaper";
import "@/assets/global.css";
import Maska from "maska";
  

const defaultLanguage = "tr";
const getCulture = () => {
  const _culture = localStorage.getItem("lang");
  return _culture != null && _culture == "undefined"
    ? _culture
    : defaultLanguage;
};
export default getCulture;

 const globalOptions: any = {
   mode: "international",
    onlyCountries: ['TR', 'AU', "DE", "US", "CA"]
 };


const i18n: any = createI18n({
  locale: getCulture(),
  fallbackLocale: "tr",
  messages,
  warnHtmlInMessage: 'off'
});


const app = createApp(App);
app.config.globalProperties.$isNullOrWhitespace = (str: string) => str == null || str.match(/^\s*$/);
app.config.globalProperties.$isTestServer = () => window.location.toString().match(/localhost|test1444/);
app.config.globalProperties.$isLocalServer = () => window.location.toString().match(/localhost/);

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueTelInput, [globalOptions])
  .use(Maska)
  .use(VueHtmlToPaper)
  .mount("#app");


import { defineComponent } from "vue";
import BaseWrapper from "@/components/BaseWrapper.vue";
import BaseButton from "../components/BaseButton.vue";
import useGTMEcommence from "@/composables/useGTMEcommence";

export default defineComponent({
  name: "failure",
  components: {
    BaseWrapper,
    BaseButton,
    },
    setup() {
        const{ eCommenceSuccess, eCommenceFailure } = useGTMEcommence();
        return { eCommenceSuccess, eCommenceFailure };
    },
  data() {
    return {
      text: "ss" as string,
      ErrorMsg: "",
    };
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch("get3DPaymentErrorById", {
          id: this.$route.params.id,
          culture: this.$i18n.locale,
        })
        .then(() => {
          // console.log(this.$store.state.ThreeDPaymentError);
          if (this.$store.state.ThreeDPaymentError.paymentStatus) {
            this.$router.push(`/Booking/${this.$i18n.locale}`);
          } else {
              this.ErrorMsg = this.$store.state.ThreeDPaymentError.paymentMessage;
              this.eCommenceFailure("Ödeme Hatası", this.$store.state.ThreeDPaymentError.paymentMessage);
          }
        });
    } else {
      this.$router.push(`/Booking/${this.$i18n.locale}`);
    }
  },
  methods: {
    goHome() {
      this.$router.push(`/Booking/${this.$i18n.locale}`);
    },
  },
});


import FormTitle from "./FormTitle.vue";
import BaseButton from "./BaseButton.vue";
import BaseModal from "./BaseModal.vue";
import OrderPreview from "./OrderPreview.vue";
import { defineComponent } from "vue";
import useClientBrowser from "../composables/useClientBrowser";
import useCreditCardType from "../composables/useCreditCardType";
import userMoneyFormat from "@/composables/userMoneyFormat";
import useGTMEcommence from "@/composables/useGTMEcommence";
declare global {
  interface Window {
    MidtransNew3ds: any;
  }
}

export default defineComponent({
  name: "FormPayment",
  components: {
    FormTitle,
    BaseButton,
    BaseModal,
    OrderPreview,
  },
  setup() {
    const { browser } = useClientBrowser();
    const { cardType } = useCreditCardType();
    const { moneyFormat } = userMoneyFormat();
    const { eCommenceSuccess, eCommenceFailure } = useGTMEcommence();

    return {
      browser,
      cardType,
      moneyFormat,
      eCommenceFailure
    };
  },
  data() {
    return {
      USE_MIDTRANS_PAYMENT: false,

      TURKEY_CLIENT_APP_ID: 215,
      INDONESIA_CLIENT_APP_ID: 101,

      totalHisse: "0 Hisse" as string,
      totalPayment: "0 TRY",
      radioSetName: "radio-set",
      selectedCurrency: "TRY",
      currOptions: [{ key: "Türk Lirasi (TRY)", val: "TRY" }],
      checkedPolicy: [],

      showPolicy: false,
      showKvk: false,
      showContactPermit: false,
      showOrgPolicy: false,

      disableBtn: true,
      isStripeError: false,
      isPolicyError: false,
      stripeErrorText: "",

      isShowPreview: false,
      hidePreview: true,
      previewData: {},
      order: {},
      submitBtnText: this.$t("submit.value"),
      isWaiting: false,

      showIframe: false,
      showThreeDSecure: false,

      isInstallmentAllowed: false,
      cardDetail: {
        CardNumber: "",
        CardFullName: "",
        CardExpiry: "",
        CardCvc: "",
        InstallmentCount: 0,
      },
    };
  },
  watch: {
    "$store.state.WakalahList.length": function (_nw) {
      if (_nw > 0) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },
    "$store.state.paymentError": function (_nw) {
      if (_nw != "") {
        this.stripeErrorText = this.$store.state.paymentError;
        this.isStripeError = true;
        this.disableBtn = false;
        this.isWaiting = false;
        this.submitBtnText = this.$t("submit.value");
        this.eCommenceFailure("Form İşlem Hatası", this.$store.state.paymentError);
      }
    },
    getculture: function (_nw) {
      if (_nw) {
        setTimeout(() => {
          this.addEventToPolicyPopup();
        }, 500);
      }
    },
  },
  methods: {
    pay() {
      this.submitBtnText = this.$t("waiting.value");
      this.disableBtn = true;
      this.isStripeError = false;
      const resInput = this.validateInputs();
      if (!resInput.valid) {
        this.$store.dispatch("inputsDataError", resInput);
        this.disableBtn = false;
        this.submitBtnText = this.$t("submit.value");
        if (resInput.type == 3) {
          this.isPolicyError = true;
        }
        return;
      } else {
        this.isPolicyError = false;
        const cardData = this.cardDetail;
        cardData.CardExpiryMonth = cardData.CardExpiry.substr(0, 2);
        cardData.CardExpiryYear = cardData.CardExpiry.slice(-2);
        this.previewData = this.createOrderPreviewData();
        this.isShowPreview = true;
        this.order = this.createOrder(cardData);
      }
    },
    processPayment() {
      this.isShowPreview = false;
      this.isWaiting = true;
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight
      );

      this.$store.dispatch("makePayment", this.order).then(() => {
        if (this.$store.state.paymentStatus.status) {
          if (
            this.$store.state.InitSettings[0].clientID ==
            this.INDONESIA_CLIENT_APP_ID
          ) {
            this.isWaiting = false;
            this.$router.push(
              `/paymentsuccess/${this.$store.state.paymentStatus.refNo}/${this.$i18n.locale}`
            );
          } else {
            if (this.$store.state.paymentStatus.threeDSecure.length > 0) {
              const responseHtml = this.$store.state.paymentStatus.threeDSecure;
              document.open('text/html');
              document.write(responseHtml);
              document.close();
            } else {
              this.isWaiting = false;
              this.$router.push(`/success/${this.$i18n.locale}`);
            }
          }
        }
        else {
          this.stripeErrorText = this.$store.state.paymentError;
          this.isStripeError = true;
          this.eCommenceFailure("Form İşlem Hatası", this.$store.state.paymentError);
        }
      });
    },
    closeOrder() {
      this.disableBtn = false;
      this.isShowPreview = false;
      this.submitBtnText = this.$t("submit.value");
    },
    onCurrChange() {
      this.$store.dispatch("selectedCurrency", this.selectedCurrency);
    },
    hidePopup() {
      this.showPolicy = false;
      this.showKvk = false;
      this.showContactPermit = false;
      this.showContactPermit = false;
      this.showOrgPolicy = false;
    },
    validateInputs() {
      const wakalah = this.$store.state.WakalahList;
      const contactInfo = this.$store.state.ContactInfo;

      if (wakalah.length == 0) {
        return { valid: false, type: 1, msg: "Wakalat can not be empty" };
      }

      if (
        contactInfo.fullname == "" ||
        contactInfo.mobile == "" ||
        contactInfo.mobile.length < 8 ||
        contactInfo.email == "" ||
        contactInfo.country == "" ||
        contactInfo.city == "" ||
        // contactInfo.town == "" ||
        contactInfo.countryCode == "" ||
        contactInfo.countryPhoneCode == ""
      ) {

        window.scroll(0, 200);
        return {
          valid: false,
          type: 2,
          msg: (Math.random() + 1).toString(36).substring(2),
        };
      }

      if (this.cardDetail.CardNumber.length < 19) {
        this.stripeErrorText = this.$t("emptyCardInfoErrorTxt.value");
        this.isStripeError = true;
        return {
          valid: false,
          type: 1,
          msg: this.$t("emptyCardInfoErrorTxt.value"),
        };
      } else {
        if (!this.validateCreditCardNumber(this.cardDetail.CardNumber)) {
          this.stripeErrorText = "Hatali Kart - Card invalid !";
          this.isStripeError = true;
          return { valid: false, type: 1, msg: "Card invalid !" };
        }
      }
      // !this.checkedPolicy.includes("terms") ||
      if (!this.checkedPolicy.includes("eyyup")) {
        return { valid: false, type: 3, msg: "policy1 not selected" };
      }
      if (!this.checkedPolicy.includes("thirdPerson")) {
        return { valid: false, type: 3, msg: "policy2 not selected" };
      }
      return { valid: true, type: 3, msg: "OK" };
    },
    createOrder(card: any) {
      const _contactInfo = this.$store.state.ContactInfo;
      if (this.checkedPolicy.includes("ContactPermit")) {
        _contactInfo.contactPermission = true;
      } else {
        _contactInfo.contactPermission = false;
      }
      if (
        this.$store.state.InitSettings[0].clientID ==
        this.INDONESIA_CLIENT_APP_ID
      ) {
        this.getCardToken(card)
          .then((res: any) => {
            this.order = {
              cardDetail: card,
              qurbanList: this.$store.state.WakalahList,
              culture: this.$i18n.locale,
              currency: this.localCurrency,
              contactInfo: _contactInfo,
              clientBrowser: this.browser,
              token: res.token_id,
              clientAppID: this.clientAppID,
            };
          })
          .catch((res: any) => {
            console.log(res);
          });
      } else {
        return {
          cardDetail: card,
          qurbanList: this.$store.state.WakalahList,
          culture: this.$i18n.locale,
          currency: this.localCurrency,
          contactInfo: _contactInfo,
          clientBrowser: this.browser,
          token: "",
          clientAppID: this.clientAppID,
        };
      }
    },
    createOrderPreviewData() {
      return {
        contactInfo: this.$store.state.ContactInfo,
        installment:
          this.cardDetail.InstallmentCount == 3
            ? this.moneyFormat(
              parseInt(this.$store.state.TotalHissePayment.total) / 3
            ) + " TRY/Ay  "
            : "",
        totalpayment:
          this.moneyFormat(
            parseInt(this.$store.state.TotalHissePayment.total)
          ) +
          " " +
          this.localCurrency,
        wakalahList: this.$store.state.WakalahList,
      };
    },
    onInputFocus(val: string, elem: any) {
      elem.parentElement.classList.add("isEmpty");
    },
    onInputBlur(val: string, elem: any, str: string) {
      if (!val) {
        elem.parentElement.classList.remove("isEmpty");
        elem.parentElement.classList.remove("invalid");
      }
      this.validateCard(val, elem, str);
      this.checkCardType();
      if (this.validateCreditCardNumber(this.cardDetail.CardNumber)) {
        elem.parentElement.classList.remove("invalid");
      } else {
        elem.parentElement.classList.add("invalid");
      }
    },
    customMaska(e: any) {
      if (!e.key.match(/^[a-zA-Z_ığüşöçĞÜŞÖÇİ ]*$/g)) {
        e.preventDefault();
      }
    },
    clearInvalid(elem: any) {
      elem.parentElement.classList.remove("invalid");
    },
    validateCardNumber(elem: any) {
      this.checkCardType();
      if (
        this.cardDetail.CardNumber.length == 19 &&
        this.validateCreditCardNumber(this.cardDetail.CardNumber)
      ) {
        elem.parentElement.classList.remove("invalid");
      }
    },
    validateCreditCardNumber(value: string) {
      if (/[^0-9-\s]+/.test(value)) return false;
      // The Luhn Algorithm.
      let nCheck = 0,
        nDigit = 0,
        bEven = false;
      value = value.replace(/\D/g, "");

      for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10);

        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
      }
      return nCheck % 10 == 0;
    },
    validateCard(val: string, elem: any, str: string) {
      if (str == "FULLNMAE" && val && val.length < 4) {
        elem.parentElement.classList.add("invalid");
      } else if (str == "CARDNUMBER" && val && val.length < 19) {
        elem.parentElement.classList.add("invalid");
      } else if (str == "MMYY" && val && parseInt(val.substr(0, 1)) > 1) {
        elem.parentElement.classList.add("invalid");
      } else if (str == "CVC" && val && val.length < 3) {
        elem.parentElement.classList.add("invalid");
      } else if (!val) {
        elem.parentElement.classList.remove("invalid");
      }
    },
    checkCardType() {
      if (
        this.cardDetail.CardNumber.length > 0 &&
        this.cardDetail.CardNumber.length >= 10
      ) {
        let theCardType = this.cardType(
          this.cardDetail.CardNumber.replaceAll(" ", "").substr(0, 8)
        );
        if (theCardType === "VISA" || theCardType === "MASTERCARD") {
          this.isInstallmentAllowed = false; // true; şimdilik bütün taksitler kaldırıldı
          this.cardDetail.InstallmentCount = 0;
        } else {
          this.isInstallmentAllowed = false;
          this.cardDetail.InstallmentCount = 0;
        }
      } else if (this.cardDetail.CardNumber.length < 10) {
        this.isInstallmentAllowed = false;
        this.cardDetail.InstallmentCount = 0;
        if (this.cardDetail.InstallmentCount == 3) {
          this.$store.dispatch("getInstallmentPrice", 0);
        }
      }
      //clear error message when card number is correct
      if (this.cardDetail.CardNumber.length == 19 && this.isStripeError) {
        this.isStripeError = false;
      }
    },
    onInstallment(e: any) {
      this.$store.dispatch("getInstallmentPrice", parseInt(e.target.value));
    },
    getCardToken(card: any) {
      //USER CARD DETAIL
      // const cardData = {
      //   card_number: card.CardNumber.replaceAll(" ", ""),
      //   card_exp_month: card.CardExpiry.substr(0, 2),
      //   card_exp_year: "20" + card.CardExpiry.slice(-2),
      //   card_cvv: card.CardCvc,
      // };

      //TEST CARAD
      const cardData = {
        card_number: "4811111111111114",
        card_exp_month: "02",
        card_exp_year: "2025",
        card_cvv: "123",
      };

      return new Promise((resolve, reject) => {
        window.MidtransNew3ds.getCardToken(cardData, {
          onSuccess: (response: any) => {
            resolve(response);
          },
          onFailure: (response: any) => {
            reject(response);
          },
        });
      });
    },
    addEventToPolicyPopup() {
      const orgPolicy = document.querySelectorAll(".org-policy");
      orgPolicy?.forEach((elem) => {
        elem.addEventListener("click", () => {
          this.showOrgPolicy = !this.showOrgPolicy;
        });
      });
    },
  },
  computed: {
    getHisseQty() {
      return (
        this.$store.state.TotalHissePayment.qty + " " + this.$t("share.value")
      );
    },
    getHisseTotal() {
      return (
        this.moneyFormat(this.$store.state.TotalHissePayment.total) +
        " " +
        this.localCurrency
      );
    },
    getHisseInstallPrice() {
      return (
        (this.$store.state.TotalHissePayment.installPrice == undefined
          ? 0
          : this.moneyFormat(
            this.$store.state.TotalHissePayment.installPrice
          )) +
        " " +
        this.localCurrency
      );
    },
    getculture() {
      return this.$i18n.locale;
    },
    localCurrency() {
      return this.$store.state.InitSettings.length > 0
        ? this.$store.state.InitSettings[0].localCurrency
        : "TRY";
      // return 101; //this.$store.state.InitSettings[0].localCurrency;
    },
    clientAppID() {
      return this.$store.state.InitSettings[0].clientID;
    },
  },
  mounted() {
    this.addEventToPolicyPopup();
  },
});

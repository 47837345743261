
import { defineComponent } from "vue";
import BaseWrapper from "@/components/BaseWrapper.vue";
import BaseButton from "../components/BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";
import userMoneyFormat from "@/composables/userMoneyFormat";
import useGTMEcommence from "@/composables/useGTMEcommence";
import AdditionalServices from "./AdditionalServices.vue";

export default defineComponent({
  name: "Success",
  components: {
    BaseWrapper,
    // BaseButton,
    BaseTable,
    AdditionalServices
  },
  setup() {
    const { moneyFormat } = userMoneyFormat();
    const { eCommenceSuccess } = useGTMEcommence();
    return { moneyFormat, eCommenceSuccess };
  },
  data() {
    return {
      text: "ss" as string,
      qurbanYear: "1444/2023",
      localCurrency: "TRY",
      sharePickupLocation: false as boolean,
      needyRecord: null as any,
      country: null,
      city: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch("getWakalahByRefNo", {
          refNo: this.$route.params.id,
          culture: this.$i18n.locale,
        })
        .then(() => {
          if (
            this.$store.state.WakalahResult.paymentStatus != "Tamamlandı" &&
            this.$store.state.WakalahResult.paymentStatus != "OK"
            //&& !this.$isLocalServer()
          ) {
            this.$router.push(`/Booking/${this.$i18n.locale}`);
          } else {
            this.sharePickupLocation = this.$store.state.WakalahResult.sharePickupLocation;
            this.needyRecord = this.$store.state.WakalahResult.needyRecord;
            this.country = this.$store.state.WakalahResult.countryName;
            this.city = this.$store.state.WakalahResult.city;
            //send success to gtm
            //console.log(this.$store.state.WakalahResult);
            this.eCommenceSuccess(this.$store.state.WakalahResult);
          }
        });
    } else {
      this.$router.push(`/Booking/${this.$i18n.locale}`);
    }
  },
  computed: {
    wakalahList() {
      return this.$store.state.WakalahResult.qurbanDetails;
    },
    wakalahTotalPayment() {
      return this.moneyFormat(
        parseInt(this.$store.state.WakalahResult.totalPayment)
      );
    },
    installmentPrice() {
      return (
        this.moneyFormat(
          parseInt(this.$store.state.WakalahResult.totalPayment) / 3
        ) + " TRY/Ay  3 Taksıt"
      );
    },
  },
  methods: {
    goHome() {
      this.$router.push(`/Booking/${this.$i18n.locale}`);
    },
    printMessage(e: Event): void {
      e.preventDefault();
      const baseUrl = window.location.origin;
      this.$htmlToPaper("printMe", { styles: [`${baseUrl}/customprint.css`] });
    },
  },
});

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a3eaaa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass('basebutton' + [(_ctx.gray ? ' basebutton-gray ' : '') + (_ctx.size == 'small' ? ' small' : '')]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSubmit'))),
    style: _normalizeStyle([_ctx.disabled ? _ctx.btndisable : '']),
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.text), 15, _hoisted_1))
}
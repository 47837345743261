import ITotalHissePayment from "@/types/TotalWakalah";
import IWakalah from "@/types/wakalah";
// import IInstallment from "@/types/IInstallment";
// import IExRate from "@/types/IExRate";

interface IExRate{
   excAUD: number,
   excTRY: number,
   excUSD: number,
   excEUR: number,
}

interface IInstallment{
   percentage: number,
   type: number
}

function calculteTotalHisse(arr:Array<IWakalah>, curr: string, excCurr: IExRate, installmentType: IInstallment) : ITotalHissePayment {
      const result = {"qty" : 0, "total" : 0, "installPrice": 0};
       result.qty = wQty(arr);
       let sum: number =  wTotal(arr);
         if(curr == 'TRY' || curr == 'Rp' || curr == 'AUD'){ sum = sum * 1}
        //  if(curr == 'USD'){ sum = Math.ceil(sum * excCurr.excUSD)}
        //  if(curr == 'EUR'){ sum = Math.ceil(sum * excCurr.excEUR)}

         if(installmentType.type == 3) {
           result.total = Math.ceil(sum); 
           result.installPrice = Math.ceil(result.total / 3);
         }else{
           result.installPrice = sum;
           result.total = sum; 
         }

       // result.total = sum;  
        return result;
       
}



// function calculteTotalHisse(arr:Array<IWakalah>, curr: string, excCurr: IExRate, installmentType: IInstallment) : ITotalHissePayment {
//       const result = {"qty" : 0, "total" : 0, "installPrice": 0};
//        result.qty = wQty(arr);
//        let sum: number =  wTotal(arr);
//          if(curr == 'TRY' || curr == 'Rp' || curr == 'AUD'){ sum = sum * 1}
//         //  if(curr == 'USD'){ sum = Math.ceil(sum * excCurr.excUSD)}
//         //  if(curr == 'EUR'){ sum = Math.ceil(sum * excCurr.excEUR)}

//          if(installmentType.type == 3) {
//            result.total = Math.ceil((sum * installmentType.percentage) + sum); 
//            result.installPrice = Math.ceil(result.total / 3);
//          }else{
//            result.installPrice = sum;
//            result.total = sum; 
//          }

//        // result.total = sum;  
//         return result;
       
// }



function wTotal( arr: Array<IWakalah>) : number{
          let i = 0, total = 0;
          for (i = 0; i < arr.length; i++)
            total += arr[i].total;
            return total;
}

function wQty(arr: Array<IWakalah>) : number{
          let i = 0, total  = 0;
          for (i = 0; i < arr.length; i++)
            total += arr[i].quantity;
          return total;
}



 export default calculteTotalHisse;       

import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    text: String,
    disabled: Boolean,
    gray: Boolean,
    size: String,
  },
  data() {
    return {
      btndisable: {
        opacity: ".3",
        cursor: "no-drop",
      },
    };
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLoader",
  props: {
    isShow: Boolean,
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showup: {
        opacity: "1",
        pointerEvents: "initial",
      },
    };
  },
});

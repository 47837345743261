import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-wrapper",
    style: _normalizeStyle([_ctx.isShow ? _ctx.showpopup : '']),
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('onClose')), ["self"]))
  }, [
    _createElementVNode("div", {
      class: "modal-content",
      style: _normalizeStyle([_ctx.Size == 'large' ? _ctx.large : ''])
    }, [
      (_ctx.Size != 'large')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
          }, "X"))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 4))
}

import { defineComponent } from "vue";
import BaseWrapper from "@/components/BaseWrapper.vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "NotFound",
  components: {
    BaseWrapper,
    BaseButton,
  },
  methods: {
    goHome() {
      this.$router.push(`/Booking/${this.$i18n.locale}`);
    },
  },
});

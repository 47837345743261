import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, mergeProps as _mergeProps, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6af1434c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wakalah" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "telerror"
}
const _hoisted_4 = {
  key: 1,
  class: "telerror"
}
const _hoisted_5 = { class: "inputs-container" }
const _hoisted_6 = { class: "inputs-items" }
const _hoisted_7 = { class: "inputs-items-row winput-main" }
const _hoisted_8 = { class: "form-element" }
const _hoisted_9 = ["placeholder", "title"]
const _hoisted_10 = { class: "form-element" }
const _hoisted_11 = { class: "form-element" }
const _hoisted_12 = ["placeholder", "title"]
const _hoisted_13 = { class: "inputs-items-row" }
const _hoisted_14 = { class: "form-element" }
const _hoisted_15 = {
  value: "",
  disabled: "",
  selected: ""
}
const _hoisted_16 = {
  value: "1",
  disabled: ""
}
const _hoisted_17 = { value: "2" }
const _hoisted_18 = { class: "form-element" }
const _hoisted_19 = { value: "1" }
const _hoisted_20 = { value: "2" }
const _hoisted_21 = { value: "3" }
const _hoisted_22 = { value: "4" }
const _hoisted_23 = { class: "form-element" }
const _hoisted_24 = ["value"]
const _hoisted_25 = {
  key: 0,
  class: "wakalah-add"
}
const _hoisted_26 = { class: "distribute" }
const _hoisted_27 = { class: "distribute-inputs" }
const _hoisted_28 = {
  class: "inputs-items-row",
  style: {"justify-content":"space-between"}
}
const _hoisted_29 = {
  key: 0,
  class: "check-inputgroup"
}
const _hoisted_30 = ["disabled"]
const _hoisted_31 = { for: "distribute" }
const _hoisted_32 = {
  key: 1,
  class: "form-element"
}
const _hoisted_33 = ["disabled"]
const _hoisted_34 = {
  value: "",
  disabled: ""
}
const _hoisted_35 = ["value", "id"]
const _hoisted_36 = {
  key: 0,
  class: "distribuCity-error"
}
const _hoisted_37 = {
  key: 2,
  class: "distribution-namepolicy"
}
const _hoisted_38 = { key: 3 }
const _hoisted_39 = {
  key: 0,
  class: "distribution-error"
}
const _hoisted_40 = {
  key: 0,
  class: "nametransfer-error"
}
const _hoisted_41 = {
  key: 1,
  class: "wakalahSecond-add"
}
const _hoisted_42 = { class: "wakalah-overseas-box" }
const _hoisted_43 = { class: "content-wrapper" }
const _hoisted_44 = {
  key: 0,
  class: "card-wrapper"
}
const _hoisted_45 = {
  key: 1,
  class: "qurbanempty-list"
}

export function render(_ctx, _cache) {
  const _component_FormTitle = _resolveComponent("FormTitle")
  const _component_VueTelInput = _resolveComponent("VueTelInput")
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_BaseCard = _resolveComponent("BaseCard")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormTitle, {
      count: "2",
      countTilte: _ctx.$t('countTilte1.value'),
      countMsg: _ctx.$t('countMsg1.value')
    }, null, 8, ["countTilte", "countMsg"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.ErrMsg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.ErrMsg), 1))
        : _createCommentVNode("", true),
      (_ctx.isPhoneError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("wakalahtelError.value")), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("wname.value").toUpperCase()), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.wName) = $event)),
                placeholder: _ctx.$t('wname.value'),
                ref: "wnameInput",
                title: _ctx.$t('wname.value')
              }, null, 8, _hoisted_9), [
                [_vModelText, _ctx.wName]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("wMobile.value")), 1),
              _createVNode(_component_VueTelInput, _mergeProps({
                onInput: _ctx.onInput,
                value: _ctx.wTelValue
              }, _ctx.inputOpts, {
                modelValue: _ctx.wTelValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.wTelValue) = $event))
              }), null, 16, ["onInput", "value", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("wemail.value").toUpperCase()), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.wemail) = $event)),
                placeholder: _ctx.$t('wemail.value'),
                ref: "wemailInput",
                title: _ctx.$t('wemail.value')
              }, null, 8, _hoisted_12), [
                [_vModelText, _ctx.wemail]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("qurban.value")), 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.wQurban) = $event)),
                ref: "qurbanInput",
                onChange: _cache[4] || (_cache[4] = e => {
                e.target.classList.remove('invalid')
              })
              }, [
                _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t("qurbanTypeSelect.value")), 1),
                _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$t("sheep.value")), 1),
                _createElementVNode("option", _hoisted_17, _toDisplayString(_ctx.$t("cattle.value")), 1)
              ], 544), [
                [_vModelSelect, _ctx.wQurban]
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("wakalah.value")), 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.wakalah) = $event)),
                onChange: _cache[6] || (_cache[6] = (...args) => (_ctx.onWakalahSelect && _ctx.onWakalahSelect(...args))),
                ref: "wakalahInput"
              }, [
                _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$t("wajib.value")), 1),
                _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.$t("aqiqah.value")), 1),
                _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.$t("nadhr.value")), 1),
                _createElementVNode("option", _hoisted_22, _toDisplayString(_ctx.$t("other.value")), 1)
              ], 544), [
                [_vModelSelect, _ctx.wakalah]
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("qurbanqty.value")), 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.qurbanqty) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qurbanqtyOpts, (qty, idx) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: idx,
                    value: qty
                  }, _toDisplayString(qty), 9, _hoisted_24))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.qurbanqty]
              ])
            ])
          ])
        ]),
        (_ctx.isShowDistributionFeature)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    (!_ctx.isDistributionSelect)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            id: "distribute",
                            value: "distribute",
                            ref: "distributeCheckref",
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.distributeCheck) = $event)),
                            disabled: _ctx.isDistributionSelect,
                            onChange: _cache[9] || (_cache[9] = (...args) => (_ctx.onVolunteerSelect && _ctx.onVolunteerSelect(...args)))
                          }, null, 40, _hoisted_30), [
                            [_vModelCheckbox, _ctx.distributeCheck]
                          ]),
                          _createElementVNode("label", _hoisted_31, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("distributechecktext.value")), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isDistributionSelect)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.distributeCity) = $event)),
                            onChange: _cache[11] || (_cache[11] = (...args) => (_ctx.onDistributeCity && _ctx.onDistributeCity(...args))),
                            disabled: _ctx.isVolunteerSelect
                          }, [
                            _createElementVNode("option", _hoisted_34, _toDisplayString(_ctx.$t("distributionCity.value")), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateOptions, (state, idx) => {
                              return (_openBlock(), _createElementBlock("option", {
                                value: state.code,
                                id: state.code,
                                key: idx
                              }, _toDisplayString(state.name), 9, _hoisted_35))
                            }), 128))
                          ], 40, _hoisted_33), [
                            [_vModelSelect, _ctx.distributeCity]
                          ]),
                          (_ctx.isDistCityError)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.$t("distribuCityErrorTxt.value")), 1))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isNameTransferAbroadSelect)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37))
                      : _createCommentVNode("", true),
                    (_ctx.isDistributionSelect && _ctx.isNameTransferAbroadSelect)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_38))
                      : _createCommentVNode("", true),
                    _createVNode(_component_BaseButton, {
                      class: "btn-lg-d-block",
                      text: _ctx.$t('add.value'),
                      onOnSubmit: _ctx.addWakalah,
                      style: {"margin":"initial"}
                    }, null, 8, ["text", "onOnSubmit"])
                  ]),
                  (_ctx.isDistError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.$t("distErrorMsg.value")), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.NameTransferError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.$t("nameTransferError.value")), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_BaseButton, {
                class: "btn-lg-d-none",
                text: _ctx.$t('add.value'),
                onOnSubmit: _ctx.addWakalah,
                style: {"background":"#2f434f !important","margin-top":"26px"}
              }, null, 8, ["text", "onOnSubmit"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isShowDistributionFeature)
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createVNode(_component_BaseButton, {
                  class: "",
                  text: _ctx.$t('add.value'),
                  onOnSubmit: _ctx.addWakalah,
                  style: {"background":"#2f434f !important","margin-top":"26px"}
                }, null, 8, ["text", "onOnSubmit"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_43, [
      (_ctx.wakalahList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wakalahList, (wakalah, dx) => {
              return (_openBlock(), _createBlock(_component_BaseCard, {
                key: dx,
                cardData: wakalah
              }, null, 8, ["cardData"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(_ctx.$t("qurbanlişstempty.value")), 1))
    ])
  ]))
}

import { defineComponent } from "vue";
import BaseWrapper from "@/components/BaseWrapper.vue";
import FormWakalah from "@/components/FormWakalah.vue";
import FormContact from "@/components/FormContact.vue";
import FormPayment from "@/components/FormPayment.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import BaseModal from "@/components/BaseModal.vue";

export default defineComponent({
  name: "Booking",
  components: {
    BaseWrapper,
    FormWakalah,
    FormContact,
    FormPayment,
    BaseLoader,
    BaseModal,
  },
  data() {
    return {
      showLoader: true,
      errorSetting: {},
        showPolicy: false
    };
  },
  created() {
    this.$store
      .dispatch("getInitSettings", { culture: this.$i18n.locale })
      .then(() => {
        if (this.$store.state.InitSettings.length > 0) {
          const { endDate } = this.$store.state.InitSettings[0];
          const _now = Date.now();
          // if (_now > endDate) {
          //     //this.$router.push(`/notification/${this.$i18n.locale}`);
          //     location.href = `/notification/${this.$i18n.locale}`;
          // }
          this.showLoader = false;
        } else {
          this.errorSetting = this.$store.state.errorSetting;
        }
      });
  },
  watch: {
    getculture: function (_nw) {
      if (_nw) {
        setTimeout(() => {
          this.addEventToPolicyPopup();
        }, 500);
      }
    },
  },
  methods: {
    hidePopup() {
      this.showPolicy = false;
    },
    addEventToPolicyPopup() {
      const policyItem = document.querySelectorAll(".policyItem");
      policyItem?.forEach((elem) => {
        elem.addEventListener("click", () => {
          this.showPolicy = !this.showPolicy;
        });
      });
    },
  },
  computed: {
    notfound() {
      return this.$store.state.errorSetting.status == 404 ? true : false;
    },
    getculture() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.addEventToPolicyPopup();
  },
});


import { defineComponent } from "vue";
import BaseWrapper from "@/components/BaseWrapper.vue";

export default defineComponent({
  name: "Notification",
  components: {
    BaseWrapper,
  },
});

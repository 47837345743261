
import { defineComponent } from "vue";
import BaseButton from "./BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";

export default defineComponent({
  name: "Preview",
  props: {
    prevData: {
      required: false,
      type: Object,
    },
  },
  components: {
    BaseButton,
    BaseTable,
  },
  data() {
    return {};
  },
});

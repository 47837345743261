<template>
  <div class="contact">
    <FormTitle count="1" :countTilte="$t('countTilte2.value')" :countMsg="$t('countMsg2.value')" />

    <div class="content-wrapper">
      <div class="contact-error" ref="contactErrorBox" v-if="isError">
        {{ $t("contactError.value") }}
      </div>

      <div class="inputs-container">
        <div class="inputs-items">
          <div class="inputs-items-row">
            <div class="form-element">
              <label>{{ $t("fullname.value").toUpperCase() }}</label>
              <input v-model="cFullname" :placeholder="$t('fullname.value')" ref="wnameInput" />
            </div>

            <div class="form-element">
              <label> {{ $t("wMobile.value") }} </label>
              <VueTelInput ref="myTelInput" @input="onInput" :value="cTelValue" v-bind="inputOpts2" v-model="cTelValue"
                :dynamicPlaceholder="$t('mobilephone.value')"></VueTelInput>
            </div>

            <div class="form-element">
              <label>{{ $t("wemail.value").toUpperCase() }} </label>
              <input v-model="cEmail" :placeholder="$t('wemail.value')" ref="wemailInput" />
            </div>
          </div>

          <div class="inputs-items-row">
            <div class="form-element">
              <select v-model="cCountry" ref="countryInput" @change="countrySelect">
                <!--<option value="" disabled>
                  {{ $t("country.value") }}
                </option>-->
                <option :value="215" id="TUR">TURKEY (TÜRKİYE)</option>
                <!--<option :value="16" id="AUS">AUSTRALIA (AVUSTRALYA)</option>
                <option :value="81" id="DEU">GERMANY (DEUTSCHLAND)</option>
                <option :value="223" id="USA">UNITED STATES</option>-->
                <!--<option
                  v-for="(country, idx) in countriesData.OptsValues"
                  :value="country.value"
                  :key="idx"
                  :id="country.id"
                >
                  {{ country.text }}
                </option>-->
              </select>
            </div>
            <div class="form-element">
              <select v-model="cCity" ref="cityInput" @change="citySelect">
                <option value="" disabled>
                  {{ $t("city.value") }}
                </option>
                <option v-for="(state, idx) in statesOpts" :value="state.id" :id="state.id" :key="idx">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form-element">
              <input v-model="cTown" :placeholder="$t('town.value')" ref="townInput" />
            </div>
          </div>
        </div>
      </div>

      <div class="contact-transfer" v-if="isContactTransferSelect">
        <div class="check-inputgroup">
          <input type="checkbox" id="dataTransfer" value="dataTransfer" @change="onDataTransfer"
            v-model="ContactTransfer" />
          <label for="dataTransfer" v-html="$t('contactInfoTransferMsg.value')"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="tss">

import { defineComponent } from 'vue';
import FormTitle from './FormTitle.vue';
import { VueTelInput } from 'vue3-tel-input';
import Countries from '@/assets/Countries';
import 'vue3-tel-input/dist/vue3-tel-input.css';


export default defineComponent({
  name: 'FormContact',
  components:{
      FormTitle,
      VueTelInput
  },
  data(){
   return{
        cFullname: "",
        cTelValue: "",
        cEmail: "",
        cCountry: "215",
        cCity: "",
        cCityName: "",
        cTown: "",
        cCountryName: "TÜRKİYE", 
        countryCode: "",
        countryPhoneCode: "90",
        isError: false,
        isValidTel: false,
        NameTransferPolicy: [],
        ContactTransfer: [],
        isContactTransferSelect: false,

        statesOpts: [],
        inputOpts2:{
           mode: "international",
           disabledFetchingCountry: true,
           preferredCountries: ["AU", "TR","DE", "US"],
           autocomplete: "off",
           maxLen: 25,
           enabledCountryCode: true,
           defaultCountry: 'TR',
           inputOptions: {
              placeholder: (this.$t('mobilephone.value')).toUpperCase(),
          }
     },

   }
  },
   watch:{
     '$store.state.ContactInfo.fullname':function(){
      const {fullname, mobile, email} = this.$store.state.ContactInfo;
        this.cFullname = fullname;
        this.cTelValue = mobile;
        this.cEmail =  email;
     },  
      contactChanged: function(_nwContacInfo){
       this.$store.dispatch('updateContactInfo', _nwContacInfo);

       if(_nwContacInfo[0] != "" &&  _nwContacInfo[2] != ""){
         this.isContactTransferSelect = true;
       }else{
         this.ContactTransfer = [];
         this.isContactTransferSelect = false;
       }
      },
      '$store.state.InputsDataError.msg.length': function(_nw){
          if (_nw) {
              debugger;
              this.isError = false;
              if (this.$store.state.InputsDataError.type == 2 || !this.isValidTel) {
                  window.scroll(0, 200);
                  this.isError = true;
              } 
          } else {this.isError = false; }
      }
  },
  methods:{
      onInput(phone, phoneObject) {
     if (phoneObject) {
       if(phone != undefined && phoneObject.country != undefined && phoneObject.country.dialCode){
           let _dailcode = "+" + phoneObject.country.dialCode;
           this.cTelValue = _dailcode;
        }
        this.countryCode = phoneObject.countryCode || 'TR';
        if(phoneObject.valid != undefined && phoneObject.valid == true){
           this.countryPhoneCode = phoneObject.countryCallingCode;
           this.cTelValue = phone;
           this.isValidTel = true
          }else{
            this.isValidTel = false
          }
      }
     //  console.log(phone, phoneObject)
    },
   countrySelect(e){
      let options = e.target.options;
      if (options.selectedIndex > -1) {
        let code = options[options.selectedIndex].getAttribute('id');
        this.cCountryName = options[options.selectedIndex].text;
        this.getStateOptsData(code);
      }
   },
   citySelect(e){
      let options = e.target.options;
      if (options.selectedIndex > -1) {
        let name = options[options.selectedIndex].text;
        this.cCityName = name;
      }
   },
   getStateOptsData(code3){
     this.statesOpts = [];
     let _states = this.countriesData.allcountries.filter(function (country) { 
                     if (country.code3 == code3) { return country; }});
      const _data =  _states[0].states;
      const _countryName = _states[0].name;

      for (let i = 0; i < _data.length; i++) {
         this.statesOpts.push({ "id": _data[i].code , "name": (_data[i].name).toUpperCase()});
         }

      if(this.statesOpts.length == 0){  
        this.statesOpts.push({ "id": 0 , "name": (_countryName).toUpperCase()}); }
        this.cCity = "";
        this.cCityName = "";
    },
    onDataTransfer(e){
      if(e.target.checked){
        this.$store.dispatch('transferContactInfo', {fullname:this.cFullname, mobile:this.cTelValue,email:this.cEmail})
      }else{   
         this.$store.dispatch('transferContactInfo', {fullname: '', mobile:'',email: ''});
      }
    }
  },
  computed:{
    countriesData(){
         let res = [];
         let _increament = 2;
        for (let i = 0; i < Countries.length; i++) {
             let temp = {};
            if (i == 7) _increament = 3;
            if (i == 12) _increament = 4;
            if (i == 182) _increament = 5;
            temp.value = i + _increament;
            temp.text = (Countries[i].name).toUpperCase();
            temp.id = Countries[i].code3;
            res.push(temp);
        }
       return { "OptsValues": res,  "allcountries" : Countries};
    },
      contactChanged() {

    return [this.cFullname, 
            this.cTelValue, 
            this.cEmail, 
            (this.cCountry).toString(),
            this.cCityName, 
            this.cTown, 
            this.cCountryName,
            this.countryCode, 
            this.countryPhoneCode];
    }
    },
    mounted() {
        this.getStateOptsData("TUR");
    }

});</script>

<style scoped lang="scss">
.contact {
  width: 100%;
  margin-bottom: 1rem;
}

.inputs-container {
  display: flex;
  margin: 20px 0px 0px 0px;
  padding-top: 8px;
}

.inputs-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputs-items-row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.form-element {
  width: 300px;
  margin: 0px 12px;

  label {
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: var(--maingray);
    margin-left: 4px;
  }

  input,
  select {
    height: 46px;
    -webkit-appearance: none;
    border: 1px solid #e1e1e1;
    padding: 8px 6px;
    outline: none;
    font: 13px "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--maingray);
    margin: 0;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 8px;
    border-color: #80919a;
  }

  select {
    cursor: pointer;
    -webkit-appearance: auto !important;
  }
}

.inputs-items {
  display: flex;
}

.form-element {
  .vue-tel-input {
    height: 46px;
    border-radius: 8px;
    border-color: #80919a;
    margin-bottom: 18px;

    input {
      height: inherit;
      -webkit-appearance: none;
      border: 1px solid #e1e1e1;
      padding: 8px 6px;
      outline: none;
      font: 13px "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: var(--maingray);
      margin: 0;
      width: 100%;
      display: block;
      margin-bottom: 20px;
      background: #fff;
    }
  }
}

.contact-error {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #00b3bd;
  background-color: #f2dede;
  font-size: 12px;
  font-weight: bold;
  width: 80%;
  margin: 20px auto;
}

.contact-transfer {
  padding: 12px;
  cursor: pointer;
  margin-bottom: 20px;

  label {
    color: #4d4d4d;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {

  .inputs-container,
  .inputs-items-row {
    flex-direction: column;
    justify-content: center;
  }

  .form-element {
    margin: 0;
  }

  .inputs-container {
    padding-top: 0px;
  }

  .inputs-items,
  .form-element,
  .wakalah-add,
  .wakalah-add button {
    width: 100% !important;
    max-width: 100%;
  }
}
</style>

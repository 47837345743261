
import { defineComponent } from "vue";
import BaseButton from "../components/BaseButton.vue";
import userMoneyFormat from "@/composables/userMoneyFormat";
import useGTMEcommence from "@/composables/useGTMEcommence";
import BaseModal from "@/components/BaseModal.vue";
import { VueTelInput } from 'vue3-tel-input';
import Countries, { ICountry, IState, ITakeZone } from '@/assets/Countries';
import axios from 'axios';
import useClientBrowser from "../composables/useClientBrowser";
import useCreditCardType from "../composables/useCreditCardType";
import DistributionCities from '@/assets/DistributionCity';

export default defineComponent({
  name: "AdditionalServices",
  props: {
    sharePickupLocation: Boolean,
    needyRecord: Object,
    country: String,
    city: String,
    refNo: String,
    qurbanDetails: Array,
  },
  components: {
    BaseButton,
    BaseModal,
    VueTelInput
  },
  setup() {
    const { moneyFormat } = userMoneyFormat();
    const { eCommenceSuccess } = useGTMEcommence();
    const { browser } = useClientBrowser();
    const { cardType } = useCreditCardType();
    return { cardType, browser, moneyFormat, eCommenceSuccess };
  },
  created() {
    axios.get("https://worldtimeapi.org/api/timezone/Europe/Istanbul")
      .then(response => {
        try {
          this.istanbulTime = new Date(response.data.datetime);
        } catch (error) {
          this.istanbulTime = new Date();
        }
      }).catch(() => {
        this.istanbulTime = new Date();
      });
  },
  data() {
    return {
      istanbulTime: null,
      localCurrency: "TRY",
      showSicakPay: false,
      showNeedy: false,
      needySaved: false,
      statesOpts: [] as Array<{ name: string, id: string }>,
      sending: false,
      submitBtnText: this.$t("submit.value"),
      disableBtn: false,
      stripeErrorText: null,
      hizmetKabul: false,
      sharePickupSelectable: false,
      sharePickupLocation2: "",
      takePartZones: [] as Array<ITakeZone>,
      takePartZone: {} as ITakeZone,
      cardDetail: {
        CardNumber: "",
        CardFullName: "",
        CardExpiry: "",
        CardCvc: "",
      },
      needy: {
        FullName: "",
        Telephone: "",
        Address: "",
        City: "",
        Town: ""
      },
      needyValidation: {
        FullName: false,
        Telephone: false,
        Address: false,
        City: false,
        Town: false
      },
      inputOpts2: {
        mode: "international",
        disabledFetchingCountry: true,
        preferredCountries: ["AU", "TR", "DE", "US"],
        autocomplete: "off",
        maxLen: 25,
        enabledCountryCode: true,
        defaultCountry: 'TR',
        inputOptions: {
          placeholder: (this.$t('mobilephone.value')).toUpperCase(),
        }
      }
    };
  },
  // created() {
  // },
  computed: {
    countriesData() {
      let res = [];
      let _increament = 2;
      for (let i = 0; i < Countries.length; i++) {
        let temp: { value?: number, text?: string, id?: string } = {};
        if (i == 7) _increament = 3;
        if (i == 12) _increament = 4;
        if (i == 182) _increament = 5;
        temp.value = i + _increament;
        temp.text = (Countries[i].name).toUpperCase();
        temp.id = Countries[i].code3;
        res.push(temp);
      }
      return { "OptsValues": res, "allcountries": Countries };
    },
  },
  watch: {
    sharePickupLocation: {
      immediate: true,
      handler(newVal, oldVal) { // watch it
        this.sharePickupLocation2 = newVal;
      }
    },
    needyRecord: {
      immediate: true,
      handler(newVal, oldVal) { // watch it
        if (newVal) {
          this.needy = {
            FullName: newVal.fullName,
            Telephone: newVal.telephone,
            Address: newVal.address,
            City: newVal.city,
            Town: newVal.town,
          };
          this.needySaved = true;
        }
        else {
          this.needy = {
            FullName: "",
            Telephone: "",
            Address: "",
            City: "",
            Town: ""
          };
          this.needySaved = false;
        }
      }
    },
    city: {
      immediate: true,
      handler() {
        var toL = function (str: string) {
          return str && str.replace(/İ/, 'i').replace(/ı/, 'ı').toLowerCase();
        }

        this.sharePickupSelectable = false;
        this.takePartZones = null;
        if (this.qurbanDetails
          && this.qurbanDetails.length
          && this.qurbanDetails.some((q: { typeNote: string, qurbanType: number, distCountryCode: string }) => q.qurbanType == 11 && q.distCountryCode == "215")) {

          var _country = this.country == "Turkey" ? "TR" : this.country;
          const countryObj = Countries.find((c: ICountry) => c.name == _country || c.code2 == _country || c.code3 == _country);
          var _city = toL(this.city);
          var cityObj = countryObj && countryObj.states.find((c: IState) => toL(c.name) == _city);
          if (/*this.$isLocalServer ||*/ cityObj?.takePartZones && cityObj.takePartZones.length) {
            this.sharePickupSelectable = true;
            this.takePartZones = cityObj?.takePartZones;
            this.takePartZone = this.takePartZones && this.takePartZones[0];
          }
        }
      }
    }
  },
  methods: {
    needySave() {
      var hasError = false;
      if (this.$isNullOrWhitespace(this.needy.FullName)) {
        hasError = true;
        this.needyValidation.FullName = true;
      }
      else {
        this.needyValidation.FullName = false;
      }

      if (this.$isNullOrWhitespace(this.needy.Telephone) || this.needy.Telephone.length < 6) {
        hasError = true;
        this.needyValidation.Telephone = true;
      }
      else {
        this.needyValidation.Telephone = false;
      }

      if (this.$isNullOrWhitespace(this.needy.Address)) {
        hasError = true;
        this.needyValidation.Address = true;
      }
      else {
        this.needyValidation.Address = false;
      }

      if (this.$isNullOrWhitespace(this.needy.City)) {
        hasError = true;
        this.needyValidation.City = true;
      }
      else {
        this.needyValidation.City = false;
      }

      if (this.$isNullOrWhitespace(this.needy.Town)) {
        hasError = true;
        this.needyValidation.Town = true;
      }
      else {
        this.needyValidation.Town = false;
      }

      if (!hasError) {
        var params = { refNo: this.refNo, ...this.needy };
        console.log(params);
        axios.post("/api/saveNeedy", params)
          .then(() => {
            this.needy = params;
            this.needySaved = true;
            this.showNeedy = false;
          })
          .catch(err => {
            console.log({ request: err.request, response: err.response });
            alert((err && err.reponse && err.response.data) || (err.response.status + " " + err.response.statusText));
          });
      }
    },

    // Payement <  
    pay() {

      this.$refs.chkHizmetKabul.style.color = "";
      this.submitBtnText = this.$t("waiting.value");
      this.disableBtn = true;
      this.stripeErrorText = null;

      if (!this.takePartZone) {
        alert("TakePartZone empty error");
        return;
      }

      const resInput = this.validateInputs();
      if (!resInput.valid) {
        this.$store.dispatch("inputsDataError", resInput);
        this.disableBtn = false;
        this.submitBtnText = this.$t("submit.value");
        if (resInput.type == 3) {
          this.isPolicyError = true;
        }
        if (resInput.type == 4) {
          this.$refs.chkHizmetKabul.style.color = "red";
        }
        return;
      } else {
        this.isPolicyError = false;
        const cardData = this.cardDetail;
        cardData.CardExpiryMonth = cardData.CardExpiry.substr(0, 2);
        cardData.CardExpiryYear = cardData.CardExpiry.slice(-2);
        this.processPayment({
          cardDetail: cardData,
          culture: this.$i18n.locale,
          clientBrowser: this.browser,
          refNo: this.refNo,
          location: this.takePartZone.name + " (" + this.takePartZone.address + ")"
        });
      }
    },
    processPayment(order: any) {
      this.isWaiting = true;
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight
      );

      this.$store.dispatch("makesharepickuporder", order).then(() => {
        if (this.$store.state.paymentStatus.status) {

          if (this.$store.state.paymentStatus.threeDSecure.length > 0) {

            const responseHtml = this.$store.state.paymentStatus.threeDSecure;
            const tab: any = window.open("about:blank", "_top");
            tab.document.write(responseHtml);
            tab.document.close();

          } else {
            this.disableBtn = false;
            this.submitBtnText = this.$t("submit.value");
            this.isWaiting = false;
            this.showSicakPay = false;
            this.sharePickupLocation2 = order.location;
          }
        }
        else {
          this.disableBtn = false;
          this.submitBtnText = this.$t("submit.value");
          this.isWaiting = false;
          this.stripeErrorText = this.$store.state.paymentError;
          this.eCommenceFailure("Form İşlem Hatası", this.$store.state.paymentError);
        }
      });
    },
    validateInputs() {
      if (this.cardDetail.CardNumber.length < 19) {
        this.stripeErrorText = this.$t("emptyCardInfoErrorTxt.value");
        return {
          valid: false,
          type: 1,
          msg: this.$t("emptyCardInfoErrorTxt.value"),
        };
      } else {
        if (!this.validateCreditCardNumber(this.cardDetail.CardNumber)) {
          this.stripeErrorText = this.$t("Hatalı Kart");
          return { valid: false, type: 1, msg: this.stripeErrorText };
        }
      }

      if (!this.hizmetKabul) {
        return { valid: false, type: 4, msg: null };
      }

      return { valid: true, type: 3, msg: "OK" };
    },
    // > Payement

    citySelect(e: any) {
      let options = e.target.options;
      if (options.selectedIndex > -1) {
        let name = options[options.selectedIndex].text;
        this.cCityName = name;
      }
    },
    getStateOptsData(code3: string) {
      this.statesOpts = DistributionCities.map(c => { return { name: c.name.toUpperCase(), id: c.code } });
      // let _states = this.countriesData.allcountries.filter(function (country: any) {
      //   if (country.code3 == code3) { return country; }
      // });
      // const _data = _states[0].states;
      // const _countryName = _states[0].name;

      // for (let i = 0; i < _data.length; i++) {
      //   this.statesOpts.push({ "id": _data[i].code, "name": (_data[i].name).toUpperCase() });
      // }

      // if (this.statesOpts.length == 0) {
      //   this.statesOpts.push({ "id": 0, "name": (_countryName).toUpperCase() });
      // }
      this.cCity = "";
      this.cCityName = "";
    },
    onInput(phone: string, phoneObject: any) {
      if (phoneObject) {
        if (phone != undefined && phoneObject.country != undefined && phoneObject.country.dialCode) {
          let _dailcode = "+" + phoneObject.country.dialCode;
          this.needy.Telephone = _dailcode;
        }
        this.countryCode = phoneObject.countryCode || 'TR';
        if (phoneObject.valid != undefined && phoneObject.valid == true) {
          this.countryPhoneCode = phoneObject.countryCallingCode;
          this.needy.Telephone = phone;
          this.isValidTel = true
        }
      } else {
        this.isValidTel = false
      }
      //  console.log(phone, phoneObject)
    },
    onInputFocus(val: any, elem: HTMLElement) {
      elem.parentElement && elem.parentElement.classList.add("isEmpty");
    },
    onInputBlur(val: any, elem: any, str: string) {
      if (!val) {
        elem.parentElement.classList.remove("isEmpty");
        elem.parentElement.classList.remove("invalid");
      }
      this.validateCard(val, elem, str);
      this.checkCardType();
      if (this.validateCreditCardNumber(this.cardDetail.CardNumber)) {
        elem.parentElement.classList.remove("invalid");
      } else {
        elem.parentElement.classList.add("invalid");
      }
    },
    customMaska(e: any) {
      if (!e.key.match(/^[a-zA-Z_ığüşöçĞÜŞÖÇİ ]*$/g)) {
        e.preventDefault();
      }
    },
    clearInvalid(elem: any) {
      elem.parentElement.classList.remove("invalid");
    },
    validateCardNumber(elem: any) {
      this.checkCardType();
      if (
        this.cardDetail.CardNumber.length == 19 &&
        this.validateCreditCardNumber(this.cardDetail.CardNumber)
      ) {
        elem.parentElement.classList.remove("invalid");
      }
    },
    validateCreditCardNumber(value: any) {
      if (/[^0-9-\s]+/.test(value)) return false;
      // The Luhn Algorithm.
      let nCheck = 0,
        nDigit = 0,
        bEven = false;
      value = value.replace(/\D/g, "");

      for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10);

        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
      }
      return nCheck % 10 == 0;
    },
    validateCard(val: any, elem: any, str: string) {
      if (str == "FULLNMAE" && val && val.length < 4) {
        elem.parentElement.classList.add("invalid");
      } else if (str == "CARDNUMBER" && val && val.length < 19) {
        elem.parentElement.classList.add("invalid");
      } else if (str == "MMYY" && val && parseInt(val.substr(0, 1)) > 1) {
        elem.parentElement.classList.add("invalid");
      } else if (str == "CVC" && val && val.length < 3) {
        elem.parentElement.classList.add("invalid");
      } else if (!val) {
        elem.parentElement.classList.remove("invalid");
      }
    },
    checkCardType() {
      if (
        this.cardDetail.CardNumber.length > 0 &&
        this.cardDetail.CardNumber.length >= 10
      ) {
        let theCardType = this.cardType(
          this.cardDetail.CardNumber.replaceAll(" ", "").substr(0, 8)
        );
        if (theCardType === "VISA" || theCardType === "MASTERCARD") {
          this.isInstallmentAllowed = true;
        } else {
          this.isInstallmentAllowed = false;
          this.cardDetail.InstallmentCount = 0;
        }
      } else if (this.cardDetail.CardNumber.length < 10) {
        this.isInstallmentAllowed = false;
        this.cardDetail.InstallmentCount = 0;
        if (this.cardDetail.InstallmentCount == 3) {
          this.$store.dispatch("getInstallmentPrice", 0);
        }
      }
      //clear error message when card number is correct
      if (this.cardDetail.CardNumber.length == 19) {
        this.stripeErrorText = null;
      }
    },
  },
  mounted() {
    this.getStateOptsData("TUR");
  }
});

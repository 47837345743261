import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cf3e0af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mytitle" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormContact = _resolveComponent("FormContact")!
  const _component_FormWakalah = _resolveComponent("FormWakalah")!
  const _component_FormPayment = _resolveComponent("FormPayment")!
  const _component_BaseLoader = _resolveComponent("BaseLoader")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_BaseWrapper = _resolveComponent("BaseWrapper")!

  return (_openBlock(), _createBlock(_component_BaseWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("bookingTitle.value")), 1),
      _createElementVNode("div", {
        class: "wakalah-warning",
        innerHTML: _ctx.$t('disclaimerTxt.value')
      }, null, 8, _hoisted_2),
      (!_ctx.notfound)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_FormContact),
            _createVNode(_component_FormWakalah),
            _createVNode(_component_FormPayment)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseLoader, {
        isShow: _ctx.showLoader,
        data: _ctx.errorSetting
      }, null, 8, ["isShow", "data"]),
      _createVNode(_component_BaseModal, {
        isShow: _ctx.showPolicy,
        onOnClose: _ctx.hidePopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "terms-text",
            innerHTML: _ctx.$t('kvkkContent.value')
          }, null, 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["isShow", "onOnClose"])
    ]),
    _: 1
  }))
}
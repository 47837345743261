
import { defineComponent } from "vue";
export default defineComponent({
  name: "FormTitle",
  props: {
    count: String,
    countTilte: String,
    countMsg: String,
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCard",
  props: {
    cardData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      trBgColor: {
        background: "red!important",
        color: "#ffffff!important",
      },
    };
  },
  computed: {
    cardTheme() {
      const _cardstyle = this.$props.cardData.qurbanType;
      let _theme = "card ";
      switch (_cardstyle) {
        case 1:
          _theme += " wajib";
          break;
        case 2:
          _theme += " newborn";
          break;
        case 3:
          _theme += " vow";
          break;
        case 11:
          _theme += " wajib";
          break;
        case 12:
          _theme += " newborn";
          break;
        case 13:
          _theme += " vow";
          break;
        default:
          _theme += " default";
      }
      return _theme;
    },
  },
  methods: {
    removeCard(id: number) {
      this.$store.dispatch("removeWakalah", id);
    },
  },
});
